import { Autocomplete, Paper, TextField } from "@mui/material";
import styled from "@emotion/styled";
import { textFieldTheme } from "../../theme";
import { useEffect, useState } from "react";

const FilterTextField = styled(TextField)(textFieldTheme);

export const CustomAutoComplete: React.FC<{
  filterAutoCompleteData: any[];
  label: string;
  id: string;
  onChange: (fieldId: string, enteredValue: string[]) => void;
  isFiltersLoading: boolean;
  isClearBtnClicked: boolean;
  setIsClearBtnClicked: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ filterAutoCompleteData, label, id, onChange, isFiltersLoading, isClearBtnClicked, setIsClearBtnClicked }) => {
  const [selectedValueArray, setSelectedValueArray] = useState<string[]>([]);

  useEffect(() => {
    setSelectedValueArray([]);
    setIsClearBtnClicked(false);
  }, [isClearBtnClicked]);

  return (
    <Autocomplete
      multiple
      limitTags={1}
      id={id}
      value={selectedValueArray}
      options={filterAutoCompleteData}
      onChange={(_event, newValueArray) => {
        let tempValueArray: string[] = [];
        let tempSelectedValueArray: string[] = [];
        newValueArray.forEach((newValue) => {
          if (newValue != null && typeof newValue === "object") {
            tempSelectedValueArray.push(newValue.id);
            tempValueArray.push(newValue);
          } else {
            tempSelectedValueArray.push(newValue);
            tempValueArray.push(newValue);
          }
        });
        setSelectedValueArray(tempValueArray);
        onChange(id, tempSelectedValueArray);
      }}
      loading={isFiltersLoading}
      sx={{
        flex: 1,
        "& .MuiSvgIcon-root": {
          color: "#B6B8C2",
        },
      }}
      size="small"
      PaperComponent={({ children }) => <Paper style={{ fontSize: 14 }}>{children}</Paper>}
      renderInput={(params) => <FilterTextField {...params} label={label} />}
    />
  );
};
