import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Box, Container, Button, ThemeProvider, Card, CardContent, Stack, Divider } from "@mui/material";
import { APP_NAME, APP_DESCRIPTION } from "../config/config";
import BACKGROUND_IMAGE from "../assets/finance-master-data-app-login-background.png";
import PRODUCT_LOGOS from "../assets/finance-master-data-app-login-logos.png";

interface PreLoaderProps {
  message?: string | null;
  hideLogo?: boolean;
  action: () => void;
}

const StatusWithAction = (props: PreLoaderProps) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100vw",
          height: "100vh",
          justifyContent: "center",
          backgroundImage: `url(${BACKGROUND_IMAGE})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <Container fixed maxWidth="xs">
          <Card
            elevation={24}
            sx={{
              borderRadius: 3,
              pt: 3,
              mx: 1,
              backgroundColor: "white",
            }}
          >
            <CardContent>
              <Box
                sx={{
                  px: 1,
                  backgroundColor: "white",
                }}
              >
                <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2} p={1}>
                  <Grid item xs={12}>
                    <img
                      alt="logo"
                      width="130"
                      height="auto"
                      src="https://wso2.cachefly.net/wso2/sites/images/brand/downloads/wso2-logo.png"
                    ></img>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography align="center" sx={{ fontWeight: "bold" }} variant="h5" color={"black"}>
                      {APP_NAME}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ pb: 2 }}>
                    <Typography align="center" sx={{ fontSize: "1em" }} color={"black"} fontWeight={"400"}>
                      {APP_DESCRIPTION}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      size="large"
                      variant="contained"
                      style={{ color: "white" }}
                      color="primary"
                      onClick={() => props.action()}
                    >
                      LOG IN
                    </Button>
                  </Grid>
                  <Grid item xs={12} mt={10}>
                    <Stack direction="column" spacing={2}>
                      <Typography align="center" color={"black"}>
                        Powered By
                      </Typography>

                      <Stack direction="row" spacing={2}>
                        <img height={22} src={PRODUCT_LOGOS} />
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} mt={3}>
                    <Typography align="center" color={"grey"} sx={{ fontSize: "0.8em" }}>
                      {"© 2023 WSO2 LLC"}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
            <Divider />
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default StatusWithAction;
