import { Button, CircularProgress, DialogActions, DialogContent, DialogTitle, Box } from "@mui/material";
import { CreditCardPayload, Employees } from "../../utils/types";
import { useEffect, useState } from "react";
import { theme } from "../../theme";
import { CustomAutoCompleteTextField } from "../form-fields/CustomAutoCompleteTextField";
import { CCNumberValidateTextField } from "../form-fields/CCNumberValidateTextField";
import { useSnackbar } from "notistack";
import { getEmployeesEmail } from "../../utils/apiService";
import { CustomTextField } from "../form-fields/CustomTextField";
import { creditCardTypes } from "../../utils/constants";

export const CreditCardFormContent: React.FC<{
  handleDataUpdate: (dataKey: string, enteredValue: string | number | string[]) => void;
  handleFormClose: () => void;
  handleFormSubmit: () => Promise<void>;
  isBtnDisable: boolean;
  isEditForm: boolean;
  data: CreditCardPayload;
}> = ({ handleDataUpdate, handleFormClose, handleFormSubmit, isBtnDisable, isEditForm, data }) => {
  const [isDataSubmitted, setIsDataSubmitted] = useState<boolean>();
  const { enqueueSnackbar } = useSnackbar();
  const [employeesEmails, setEmployeesEmail] = useState<string[]>([]);
  const [isDataFetched, setIsDataFetched] = useState<boolean>(false);

  useEffect(() => {
    const loadGlCodes = async () => {
      try {
        const employeesEmail: Employees[] = await getEmployeesEmail();
        employeesEmail.map((employeeEmail) => setEmployeesEmail((prevData) => [...prevData, employeeEmail.workEmail]));
        setIsDataFetched(true);
      } catch (error) {
        enqueueSnackbar(
          "Error retrieving employee emails. Please try again. If the issue persists, contact Internal Apps Team",
          {
            variant: "error",
          }
        );
        handleFormClose();
      }
    };
    loadGlCodes();
  }, []);

  const handleSubmitBtnClick = () => {
    setIsDataSubmitted(false);
    handleFormSubmit();
    setIsDataSubmitted(true);
  };

  return isDataFetched ? (
    <>
      <DialogTitle sx={{ paddingBottom: 4 }}>
        {isEditForm ? "Add New Credit Card" : "Update Existing Credit Card"}
      </DialogTitle>
      <div>
        <DialogContent>
          <CustomAutoCompleteTextField
            autoCompleteData={[creditCardTypes.AMEX, creditCardTypes.SVB]}
            dataType="string"
            freeForm={false}
            id="ccProviderCode"
            initialValue={data.ccProviderCode}
            isMultiple={false}
            isRequired={true}
            label="CC Provider Code"
            onChange={handleDataUpdate}
          />
          <CCNumberValidateTextField
            label="CC Number"
            type="text"
            id="ccNumber"
            initialValue={data.ccNumber}
            isRequired={true}
            onChange={handleDataUpdate}
            selectedText={data.ccProviderCode}
          />
          <CustomAutoCompleteTextField
            autoCompleteData={employeesEmails}
            dataType="string"
            freeForm={false}
            id="employeeEmail"
            initialValue={data.employeeEmail}
            isMultiple={false}
            isRequired={true}
            label="Employee Email"
            onChange={handleDataUpdate}
          />
          <CustomAutoCompleteTextField
            autoCompleteData={employeesEmails}
            dataType="string"
            freeForm={true}
            id="leadEmails"
            initialValue={data.leadEmails}
            isMultiple={true}
            isRequired={true}
            label="Lead Email / Emails"
            onChange={handleDataUpdate}
          />
          <CustomTextField
            label="Comment"
            type="text"
            id="comment"
            initialValue={data.comment}
            isRequired={false}
            onChange={handleDataUpdate}
          />
        </DialogContent>
      </div>
      <DialogActions sx={{ paddingTop: 5 }}>
        <Button onClick={handleFormClose} sx={{ color: theme.palette.text.secondary }} size="large">
          Cancel
        </Button>
        <Button variant="contained" size="large" onClick={handleSubmitBtnClick} disabled={isBtnDisable}>
          {isDataSubmitted && <CircularProgress size={"23px"} sx={{ marginRight: "20px" }} />}
          {isEditForm ? "Submit" : "Update"}
        </Button>
      </DialogActions>
    </>
  ) : (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="60vh">
      <CircularProgress />
    </Box>
  );
};
