import { Skeleton, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useTabContext } from "../../utils/context/tab-context";
import { tableColumns } from "../../utils/constants";

export const SkeletonTable = () => {
  const { selectedTab } = useTabContext();
  return (
    <Table sx={{ marginY: selectedTab === "expenseTypes" ? "10px" : "20px" }}>
      <TableHead>
        <TableRow sx={{ display: "flex" }}>
          {tableColumns[selectedTab].map((tableColumnDetails, index) => {
            return (
              <TableCell
                key={index}
                sx={{
                  flex: tableColumnDetails.flex,
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: index === 0 ? "10px" : "5px",
                  paddingBottom: selectedTab === "expenseTypes" ? 0 : undefined,
                }}
              >
                {tableColumnDetails.headerName}
              </TableCell>
            );
          })}
          <TableCell
            sx={{
              flex: 1.2,
              paddingRight: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              paddingBottom: selectedTab === "expenseTypes" ? 0 : undefined,
            }}
          >
            Actions
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {[...Array(100)].map((_, i) => (
          <TableRow key={i} sx={{ display: "flex" }}>
            {tableColumns[selectedTab].map((tableColumnDetails, index) => {
              return (
                <TableCell
                  key={index}
                  component="th"
                  scope="row"
                  sx={{ flex: tableColumnDetails.flex, paddingLeft: index === 0 ? "10px" : "5px" }}
                >
                  <Skeleton animation="wave" variant="text" />
                </TableCell>
              );
            })}
            <TableCell sx={{ flex: 1.2, paddingRight: "10px" }}>
              <Skeleton animation="wave" variant="text" />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
