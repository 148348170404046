import { DialogTitle, DialogContent, DialogActions, Button, CircularProgress, Box } from "@mui/material";
import { DepartmentPayload, GlCode, GlCodeDropdown } from "../../utils/types";
import { CustomTextField } from "../form-fields/CustomTextField";
import { CustomDropDown } from "../form-fields/CustomDropDown";
import { useState, useEffect } from "react";
import { getGlCodes } from "../../utils/apiService";
import { useSnackbar } from "notistack";
import { theme } from "../../theme";

export const DepartmentFormContent: React.FC<{
  handleDataUpdate: (dataKey: string, enteredValue: string | number) => void;
  handleFormClose: () => void;
  handleFormSubmit: () => Promise<void>;
  isBtnDisable: boolean;
  isEditForm: boolean;
  data: DepartmentPayload;
}> = ({ handleDataUpdate, handleFormClose, handleFormSubmit, isBtnDisable, isEditForm, data }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [glCodeList, setGlCodeList] = useState<GlCodeDropdown[]>([]);
  const [isDataFetched, setIsDataFetched] = useState<boolean>(false);
  const [isDataSubmitted, setIsDataSubmitted] = useState<boolean>();

  useEffect(() => {
    const loadGlCodes = async () => {
      try {
        const glCodeRecords: GlCode[] = await getGlCodes();
        glCodeRecords.map((glCodeRecord) =>
          setGlCodeList((prevData) => [...prevData, { glCodeId: glCodeRecord.id, glCode: glCodeRecord.glCode }])
        );
        setIsDataFetched(true);
      } catch (error) {
        enqueueSnackbar(
          "Error retrieving Gl Code data. Please try again. If the issue persists, contact Internal Apps Team",
          {
            variant: "error",
          }
        );
        handleFormClose();
      }
    };
    loadGlCodes();
  }, []);

  const handleSubmitBtnClick = () => {
    setIsDataSubmitted(false);
    handleFormSubmit();
    setIsDataSubmitted(true);
  };

  return isDataFetched ? (
    <>
      <DialogTitle sx={{ paddingBottom: 4 }}>
        {isEditForm ? "Add New Department" : "Update Existing Department"}
      </DialogTitle>
      <div>
        <DialogContent>
          <CustomTextField
            label="Department"
            type="text"
            id="employeeDepartment"
            initialValue={data.employeeDepartment}
            isRequired={true}
            onChange={handleDataUpdate}
          />
          <CustomTextField
            label="Engagement Code"
            type="text"
            id="engagementCode"
            initialValue={data.engagementCode}
            isRequired={true}
            onChange={handleDataUpdate}
          />
          <CustomDropDown
            label="GL Code"
            id="glCodeId"
            initialValue={data.glCodeId}
            isRequired={true}
            onChange={handleDataUpdate}
            dropdownValues={glCodeList}
          />
        </DialogContent>
      </div>
      <DialogActions sx={{ paddingTop: 5 }}>
        <Button onClick={handleFormClose} sx={{ color: theme.palette.text.secondary }} size="large">
          Cancel
        </Button>
        <Button variant="contained" size="large" onClick={handleSubmitBtnClick} disabled={isBtnDisable}>
          {isDataSubmitted && <CircularProgress size={"23px"} sx={{ marginRight: "20px" }} />}
          {isEditForm ? "Submit" : "Update"}
        </Button>
      </DialogActions>
    </>
  ) : (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="60vh">
      <CircularProgress />
    </Box>
  );
};
