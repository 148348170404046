import styled from "@emotion/styled";
import { Autocomplete, Paper, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { textFieldTheme } from "../../theme";

const FilterTextField = styled(TextField)(textFieldTheme);

export const CustomAutoCompleteTextField: React.FC<{
  label: string;
  id: string;
  autoCompleteData: any[];
  isMultiple: boolean;
  isRequired: boolean;
  dataType: string;
  initialValue:
    | string
    | number
    | string[]
    | null
    | {
        id: number;
        label: string;
      };
  freeForm: boolean;
  onChange: (dataKey: string, enteredValue: string | number | string[]) => void;
}> = ({ id, label, autoCompleteData, onChange, isMultiple, dataType, initialValue, isRequired, freeForm }) => {
  let selectedValueArray: string[] = [];
  let selectedValue: string = "";
  const [focused, setFocused] = useState<boolean>(false);
  const [errorSelectedValue, setErrorSelectedValue] = useState<string | string[]>(isMultiple ? [] : "");

  useEffect(() => {
    if (initialValue) {
      if (Array.isArray(initialValue)) {
        setErrorSelectedValue(initialValue);
      } else if (typeof initialValue == "string" || typeof initialValue == "number") {
        setErrorSelectedValue(initialValue.toString());
      } else {
        setErrorSelectedValue(initialValue.label);
      }
    }
  }, []);

  const handleError = () => {
    return (Array.isArray(errorSelectedValue) ? errorSelectedValue?.length === 0 : !errorSelectedValue) && focused;
  };

  const onAutoCompleteChange = (_event: React.SyntheticEvent<Element, Event>, newValue: any) => {
    if (isMultiple) {
      newValue.forEach((value: any) => {
        if (value != null && typeof value === "object") {
          selectedValueArray.push(value.id);
        } else {
          selectedValueArray.push(value.trim());
        }
      });
      setErrorSelectedValue(selectedValueArray);
      onChange(id, selectedValueArray);
    } else {
      if (newValue != null && typeof newValue === "object") {
        selectedValue = newValue.id;
        setErrorSelectedValue(newValue.id);
        onChange(id, newValue.id);
      } else if (newValue === null) {
        if (dataType === "string") {
          selectedValue = "";
          setErrorSelectedValue("");
          onChange(id, "");
        } else {
          selectedValue = "";
          setErrorSelectedValue("");
          onChange(id, 0);
        }
      } else {
        selectedValue = newValue.trim();
        setErrorSelectedValue(newValue);
        onChange(id, newValue.trim());
      }
    }
  };

  return (
    <Autocomplete
      multiple={isMultiple}
      freeSolo={freeForm}
      id={id}
      options={autoCompleteData}
      value={initialValue === "" ? null : initialValue}
      onInputChange={!isMultiple && freeForm ? (_event, newValue) => onAutoCompleteChange(_event, newValue) : undefined}
      onChange={
        (isMultiple && freeForm) || (!isMultiple && !freeForm)
          ? (_event, newValue) => onAutoCompleteChange(_event, newValue)
          : undefined
      }
      sx={{
        "& .MuiSvgIcon-root": {
          color: "#B6B8C2",
        },
        "& .MuiChip-root": {
          borderRadius: "5px",
        },
      }}
      PaperComponent={({ children }) => <Paper style={{ fontSize: 14 }}>{children}</Paper>}
      renderInput={(params) => (
        <FilterTextField
          {...params}
          label={label}
          helperText={isMultiple && "For custom text, type and press Enter"}
          required={isRequired}
          error={handleError()}
          onBlur={() => {
            isRequired && setFocused(true);
          }}
        />
      )}
    />
  );
};
