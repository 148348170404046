import { Dialog } from "@mui/material";
import { useState, useEffect } from "react";
import { postData, updateData } from "../../utils/apiService";
import { useFetchContext } from "../../utils/context/table-context";
import { SubsidiaryFormContent } from "../form-content/SubsidiaryFormContent";
import { DepartmentFormContent } from "../form-content/DepartmentFormContent";
import {
  CreditCard,
  CreditCardPayload,
  Department,
  DepartmentPayload,
  ExpenseType,
  ExpenseTypePayload,
  Subsidiary,
  SubsidiaryPayload,
} from "../../utils/types";
import { areDataEqual, getUpdatedData, isAllFieldsFilled, setInitialData } from "../../utils/utils";
import { useSnackbar } from "notistack";
import { PopupTransition } from "../PopupTransition";
import { useTabContext } from "../../utils/context/tab-context";
import { theme } from "../../theme";
import { ExpenseTypeFormContent } from "../form-content/ExpenseTypeFormContent";
import { CreditCardFormContent } from "../form-content/CreditCardFormContent";

export const FormDialog: React.FC<{
  open: boolean;
  handleFormDialogClose: () => void;
  initialData?: Subsidiary | Department | ExpenseType | CreditCard;
}> = ({ open, handleFormDialogClose, initialData }) => {
  const { selectedTab } = useTabContext();
  const [formData, setFormData] = useState<
    SubsidiaryPayload | DepartmentPayload | ExpenseTypePayload | CreditCardPayload
  >(setInitialData(selectedTab, initialData));
  const [isBtnDisable, setIsBtnDisable] = useState<boolean>(true);
  const { setFetchData } = useFetchContext();
  const { enqueueSnackbar } = useSnackbar();
  const isEditForm: boolean = !initialData;

  const loadFormContent = () => {
    switch (selectedTab) {
      case "subsidiaries":
        return (
          <SubsidiaryFormContent
            handleDataUpdate={handleDataUpdate}
            handleFormClose={handleFormDialogClose}
            handleFormSubmit={handleFormSubmit}
            isBtnDisable={isBtnDisable}
            isEditForm={isEditForm}
            data={formData as SubsidiaryPayload}
          />
        );
      case "departments":
        return (
          <DepartmentFormContent
            handleDataUpdate={handleDataUpdate}
            handleFormClose={handleFormDialogClose}
            handleFormSubmit={handleFormSubmit}
            isBtnDisable={isBtnDisable}
            isEditForm={isEditForm}
            data={formData as DepartmentPayload}
          />
        );
      case "expenseTypes":
        return (
          <ExpenseTypeFormContent
            handleDataUpdate={handleDataUpdate}
            handleFormClose={handleFormDialogClose}
            handleFormSubmit={handleFormSubmit}
            isBtnDisable={isBtnDisable}
            isEditForm={isEditForm}
            data={formData as ExpenseTypePayload}
          />
        );
      case "creditCards":
        return (
          <CreditCardFormContent
            handleDataUpdate={handleDataUpdate}
            handleFormClose={handleFormDialogClose}
            handleFormSubmit={handleFormSubmit}
            isBtnDisable={isBtnDisable}
            isEditForm={isEditForm}
            data={formData as CreditCardPayload}
          />
        );
    }
  };

  const handleDataUpdate = (dataKey: string, enteredValue: string | number | string[]) => {
    if (Array.isArray(enteredValue)) {
      if (enteredValue.every((value) => value.trim() != "")) {
        setFormData((prevData) => ({
          ...prevData,
          [dataKey]: enteredValue,
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          [dataKey]: [...enteredValue.filter((value) => value != "")],
        }));
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [dataKey]: enteredValue,
      }));
    }
  };

  const handleFormSubmit = async () => {
    setIsBtnDisable(true);
    if (initialData) {
      const updatedData = getUpdatedData(initialData, formData);
      try {
        await updateData(selectedTab, updatedData, initialData.id.toString());
        enqueueSnackbar("Data update successfully", { variant: "success" });
        setFetchData(true);
      } catch (error) {
        enqueueSnackbar("Error updating data. Please try again. If the issue persists, contact Internal Apps Team", {
          variant: "error",
        });
      }
    } else {
      try {
        await postData(selectedTab, formData);
        enqueueSnackbar("Data added successfully", { variant: "success" });
        setFetchData(true);
      } catch (error) {
        enqueueSnackbar("Error adding data. Please try again. If the issue persists, contact Internal Apps Team", {
          variant: "error",
        });
      }
    }
    handleFormDialogClose();
  };

  useEffect(() => {
    if (initialData) {
      if (areDataEqual(initialData, formData)) {
        setIsBtnDisable(true);
      } else {
        if (isAllFieldsFilled(selectedTab, formData)) {
          setIsBtnDisable(false);
        } else {
          setIsBtnDisable(true);
        }
      }
    } else {
      if (isAllFieldsFilled(selectedTab, formData)) {
        setIsBtnDisable(false);
      } else {
        setIsBtnDisable(true);
      }
    }
  }, [formData]);

  return (
    <Dialog
      open={open}
      onClose={handleFormDialogClose}
      sx={{ borderRadius: "30px" }}
      TransitionComponent={PopupTransition}
      PaperProps={{
        sx: { width: "100vw" },
        style: {
          backgroundColor: theme.palette.secondary.main,
          backgroundImage: "none",
          borderRadius: 15,
          padding: 15,
        },
      }}
    >
      {loadFormContent()}
    </Dialog>
  );
};
