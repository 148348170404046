import { BaseURLAuthClientConfig } from "@asgardeo/auth-react";

declare global {
  interface Window {
    config: {
      REACT_APP_ASGARDEO_BASE_URL: string;
      REACT_APP_AUTH_SIGN_IN_REDIRECT_URL: string;
      REACT_APP_AUTH_SIGN_OUT_REDIRECT_URL: string;
      REACT_APP_ASGARDEO_CLIENT_ID: string;
      REACT_APP_BACKEND_BASE_URL: string;
      APP_NAME: string;
      ROLES: string[];
    };
  }
}

export const APP_NAME: string = `${window.config.APP_NAME}`;
export const APP_DESCRIPTION: string =
  "Manage financial master data (subsidiaries, departments, expense types, and credit card details)";

export const initialUserPrivilegeRequest = false;
export const ROLES = window.config.ROLES;

export const AsgardioConfig: BaseURLAuthClientConfig = {
  signInRedirectURL: `${window.config.REACT_APP_AUTH_SIGN_IN_REDIRECT_URL}`,
  signOutRedirectURL: `${window.config.REACT_APP_AUTH_SIGN_OUT_REDIRECT_URL}`,
  clientID: `${window.config.REACT_APP_ASGARDEO_CLIENT_ID}`,
  baseUrl: `${window.config.REACT_APP_ASGARDEO_BASE_URL}`,
  scope: ["openid", "email", "groups"],
};

export const ServiceBaseUrl = `${window.config.REACT_APP_BACKEND_BASE_URL}`;

export const AppConfig = {
  serviceUrls: {
    userPrivileges: ServiceBaseUrl + "/user-privileges",
    userData: ServiceBaseUrl + "/user-info",
  },
};
