import {
  CreditCard,
  CreditCardPayload,
  Department,
  DepartmentPayload,
  ExpenseType,
  ExpenseTypePayload,
  Subsidiary,
  SubsidiaryPayload,
} from "./types";

export const isSubsidiary = (record: Subsidiary | Department | ExpenseType | CreditCard): record is Subsidiary => {
  return record.hasOwnProperty("legalName") && record.hasOwnProperty("id");
};

export const isDepartment = (record: Subsidiary | Department | ExpenseType | CreditCard): record is Department => {
  return record.hasOwnProperty("employeeDepartment") && record.hasOwnProperty("id");
};

export const isExpenseType = (record: Subsidiary | Department | ExpenseType | CreditCard): record is ExpenseType => {
  return record.hasOwnProperty("expenseType") && record.hasOwnProperty("id");
};

export const isCreditCard = (record: Subsidiary | Department | ExpenseType | CreditCard): record is CreditCard => {
  return record.hasOwnProperty("ccNumber") && record.hasOwnProperty("id");
};

export const isSubsidiaryPayload = (
  record: SubsidiaryPayload | DepartmentPayload | ExpenseTypePayload | CreditCardPayload
): record is SubsidiaryPayload => {
  return record.hasOwnProperty("legalName");
};

export const isDepartmentPayload = (
  record: SubsidiaryPayload | DepartmentPayload | ExpenseTypePayload | CreditCardPayload
): record is DepartmentPayload => {
  return record.hasOwnProperty("employeeDepartment");
};

export const isExpenseTypePayload = (
  record: SubsidiaryPayload | DepartmentPayload | ExpenseTypePayload | CreditCardPayload
): record is ExpenseTypePayload => {
  return record.hasOwnProperty("expenseType");
};

export const isCreditCardPayload = (
  record: SubsidiaryPayload | DepartmentPayload | ExpenseTypePayload | CreditCardPayload
): record is CreditCardPayload => {
  return record.hasOwnProperty("ccNumber");
};
