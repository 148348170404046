import { useEffect, useState } from "react";
import { List, ListItemButton, ListItemText, Drawer, Box, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { SIDEBAR_WIDTH, tabLabels, tabUrls } from "../utils/constants";

export const Sidebar = () => {
  const [selectedTab, setSelectedTab] = useState<keyof typeof tabLabels>();
  const location = useLocation();

  useEffect(() => {
    const currentTab = Object.keys(tabUrls).find((tab) => tabUrls[tab as keyof typeof tabLabels] === location.pathname);
    setSelectedTab(currentTab as keyof typeof tabLabels);
  });

  return (
    <Drawer
      sx={{
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: SIDEBAR_WIDTH,
          boxSizing: "border-box",
          backgroundColor: "secondary.main",
          zIndex: 0,
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Box height={"80px"} />
      <List>
        {(Object.keys(tabLabels) as Array<keyof typeof tabLabels>).map((tab, index) => (
          <ListItemButton
            key={index}
            selected={selectedTab === tab}
            component={Link}
            to={tabUrls[tab]}
            sx={{
              "&.Mui-selected, &.Mui-selected:hover": {
                backgroundColor: "primary.main",
                color: "white",
              },
              ":hover": {
                backgroundColor: "primary.light",
              },
            }}
          >
            <ListItemText>
              <Typography fontSize={17}>{tabLabels[tab]}</Typography>
            </ListItemText>
          </ListItemButton>
        ))}
      </List>
    </Drawer>
  );
};
