import { ThemeProvider } from "@mui/material";
import { theme } from "./theme";
import { Provider } from "react-redux";
import { store } from "./slices/store";
import { AuthProvider } from "@asgardeo/auth-react";
import { AsgardioConfig } from "./config/config";
import AppAuthProvider from "./context/AuthContext";
import AppHandler from "./app/AppHandler";
import { SnackbarProvider } from "notistack";

export default function App() {
  return (
    <SnackbarProvider maxSnack={3}>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <AuthProvider config={AsgardioConfig}>
            <AppAuthProvider>
              <AppHandler />
            </AppAuthProvider>
          </AuthProvider>
        </Provider>
      </ThemeProvider>
    </SnackbarProvider>
  );
}
