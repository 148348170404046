import { Avatar, Box, Divider, IconButton, Stack, Typography } from "@mui/material";
import { SettingsMenu } from "./SettingsMenu";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { getUserInfo } from "../../utils/apiService";
import { User } from "../../utils/types";
import { useSnackbar } from "notistack";
import { APP_NAME } from "../../config/config";
import logo from "../../assets/logo.png";

export const TopStickyBar: React.FC<{ setIsUserImageFetching: Dispatch<SetStateAction<boolean>> }> = ({
  setIsUserImageFetching,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [userEmail, setUserEmail] = useState<string>();
  const [userImage, setUserImage] = useState<string>();
  const { enqueueSnackbar } = useSnackbar();

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        let userData: User = await getUserInfo();
        setUserEmail(userData.email ?? "");
        setUserImage(userData.image);
      } catch (error) {
        enqueueSnackbar(
          "Error retrieving user data. Please try again. If the issue persists, contact Internal Apps Team",
          {
            variant: "error",
          }
        );
      } finally {
        setIsUserImageFetching(false);
      }
    };

    fetchUserInfo();
  }, []);

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      paddingRight={"20px"}
      paddingY={"5px"}
      bgcolor={"secondary.main"}
      zIndex={2}
      borderBottom={"1px solid"}
      borderColor={"#3E4350"}
    >
      <Box
        display="flex"
        justifyContent="flex-end"
        component="img"
        alt="The house from the offer."
        src={logo}
        height={25}
        paddingLeft={"20px"}
      />
      <Divider
        orientation="vertical"
        sx={{ borderRightWidth: 2, paddingLeft: "20px", marginRight: "20px", fontSize: 16, height: 20 }}
      />
      <Typography fontSize={16} fontWeight={600} color={"white"} width={"100%"}>
        {APP_NAME}
      </Typography>
      <Typography color={"white"} paddingRight={"10px"} fontSize={14}>
        {userEmail}
      </Typography>
      <IconButton onClick={handleMenuOpen} size="small">
        <Avatar src={userImage} sx={{ width: 40, height: 40 }} />
      </IconButton>
      <SettingsMenu handleMenuClose={handleMenuClose} anchorEl={anchorEl} />
    </Stack>
  );
};
