import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  CircularProgress,
} from "@mui/material";
import { useState } from "react";
import { deleteData } from "../../utils/apiService";
import { CreditCard, Department, ExpenseType, Subsidiary } from "../../utils/types";
import { useFetchContext } from "../../utils/context/table-context";
import { useSnackbar } from "notistack";
import { PopupTransition } from "../PopupTransition";
import { useTabContext } from "../../utils/context/tab-context";
import { theme } from "../../theme";
import { AxiosError } from "axios";

export const DeleteConfirmationDialog: React.FC<{
  open: boolean;
  handleDeleteConfirmationClose: () => void;
  initialData: Subsidiary | Department | ExpenseType | CreditCard;
}> = ({ open, handleDeleteConfirmationClose, initialData }) => {
  const { setFetchData } = useFetchContext();
  const { enqueueSnackbar } = useSnackbar();
  const [isBtnDisable, setIsBtnDisable] = useState<boolean>(false);
  const { selectedTab } = useTabContext();

  const handleYesBtnClick = async () => {
    setIsBtnDisable(true);
    try {
      await deleteData(selectedTab, initialData.id.toString());
      enqueueSnackbar("Data deleted successfully", { variant: "success" });
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 409) {
          enqueueSnackbar(
            "Cannot proceed with this operation due to linked data. Delete other active connections to continue",
            { variant: "error" }
          );
        }
      } else {
        enqueueSnackbar("Error deleting data. Please try again. If the issue persists, contact Internal Apps Team", {
          variant: "error",
        });
      }
    }
    handleDeleteConfirmationClose();
    setFetchData(true);
  };

  const handleNoBtnClick = () => {
    handleDeleteConfirmationClose();
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={PopupTransition}
      keepMounted
      onClose={handleDeleteConfirmationClose}
      PaperProps={{
        style: {
          backgroundColor: theme.palette.secondary.main,
          backgroundImage: "none",
          paddingRight: "10px",
          paddingBottom: "10px",
        },
      }}
    >
      <DialogTitle color={"white"}>{"Delete Confirmation"}</DialogTitle>
      <DialogContent>
        <DialogContentText color={theme.palette.text.primary}>
          {"Are you sure you want to delete this record?"}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ paddingTop: "20px" }}>
        <Button onClick={handleNoBtnClick} sx={{ color: theme.palette.text.secondary }}>
          No
        </Button>
        <Button onClick={handleYesBtnClick} variant="contained" disabled={isBtnDisable}>
          {isBtnDisable && <CircularProgress size={"23px"} sx={{ marginRight: "20px" }} />}
          {"Yes"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
