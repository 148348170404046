import { Stack, Tooltip, Typography } from "@mui/material";
import { InfoOutlined as InfoIcon } from "@mui/icons-material";
import { Department, ExpenseType } from "../../utils/types";
import { theme } from "../../theme";
import { isDepartment, isExpenseType } from "../../utils/typeGuards";
import { expenseTypeTooltipFieldsWithId, glCodeTooltipFieldsWithId, tooltips } from "../../utils/constants";

const handleContent = (row: Department | ExpenseType, tooltipType: keyof typeof tooltips) => {
  if (tooltipType === "glCodeTooltip") {
    if (isDepartment(row)) {
      return Object.entries(glCodeTooltipFieldsWithId).map(([key, value], index) => {
        return (
          <Typography
            key={index}
            fontSize={15}
            paddingBottom={Object.keys(glCodeTooltipFieldsWithId).length != index + 1 ? 1.5 : undefined}
            color={theme.palette.text.primary}
          >
            {value}: <span style={{ color: "white" }}>{row[key as keyof Department] || "N/A"}</span>
          </Typography>
        );
      });
    }
    if (isExpenseType(row)) {
      return Object.entries(glCodeTooltipFieldsWithId).map(([key, value], index) => {
        return (
          <Typography
            key={index}
            fontSize={15}
            paddingBottom={Object.keys(glCodeTooltipFieldsWithId).length != index + 1 ? 1.5 : undefined}
            color={theme.palette.text.primary}
          >
            {value}: <span style={{ color: "white" }}>{row[key as keyof ExpenseType] || "N/A"}</span>
          </Typography>
        );
      });
    }
  } else if (tooltipType === "expenseTypeTooltip") {
    return Object.entries(expenseTypeTooltipFieldsWithId).map(([key, value], index) => {
      return (
        <Typography
          key={index}
          fontSize={15}
          paddingBottom={Object.keys(expenseTypeTooltipFieldsWithId).length != index + 1 ? 1.5 : undefined}
          color={theme.palette.text.primary}
        >
          {value}: <span style={{ color: "white" }}>{(row as ExpenseType)[key as keyof ExpenseType] || "N/A"}</span>
        </Typography>
      );
    });
  } else {
    throw Error("Tooltip Type Mismatch");
  }
};

const handlePlaceholder = (row: Department | ExpenseType, tooltipType: keyof typeof tooltips) => {
  if (tooltipType === "glCodeTooltip") {
    return row.glCode;
  }
  if (tooltipType === "expenseTypeTooltip") {
    return (
      <Typography fontSize={"0.875rem"} sx={{ wordBreak: "break-word", paddingTop: 0 }}>
        {(row as ExpenseType).expenseType}
      </Typography>
    );
  }
};

const handleEmptyValues = (row: Department | ExpenseType, tooltipType: keyof typeof tooltips) => {
  let isExpenseTypeValid: boolean = true;

  if (tooltipType === "expenseTypeTooltip") {
    const expenseTypeDescription: string | null = (row as ExpenseType).expenseTypeDescription;
    isExpenseTypeValid = expenseTypeDescription != null && expenseTypeDescription.trim().length > 0;
  }
  if (isExpenseTypeValid) {
    return (
      <div style={{ paddingLeft: "5px", fontSize: "14px" }}>
        <Tooltip
          title={<div style={{ padding: 10 }}>{handleContent(row, tooltipType)}</div>}
          placement="left"
          arrow
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: theme.palette.secondary.light,
                border: "2px solid rgba(255, 255, 255, 0.12)",
                "& .MuiTooltip-arrow": {
                  color: theme.palette.secondary.light,
                  "&:before": {
                    border: "2px solid rgba(255, 255, 255, 0.12)",
                  },
                },
              },
            },
          }}
        >
          <InfoIcon fontSize="inherit" />
        </Tooltip>
      </div>
    );
  }
};

export const TooltipCell: React.FC<{
  row: Department | ExpenseType;
  tooltipType: keyof typeof tooltips;
}> = ({ row, tooltipType }) => {
  return (
    <Stack direction={"row"}>
      {handlePlaceholder(row, tooltipType)}
      {handleEmptyValues(row, tooltipType)}
    </Stack>
  );
};
