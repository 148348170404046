import { BasicUserInfo, DecodedIDTokenPayload } from "@asgardeo/auth-react";

export type AuthFlowState = "start" | "l_user_privileges" | "e_user_privileges" | "end";

export enum Role {
  EMPLOYEE = "EMPLOYEE",
  ALL = "ALL",
}

export interface AuthState {
  status: "failed" | "loading" | "idle" | "success";
  mode: "active" | "maintenance";
  statusMessage: string | null;
  isAuthenticated: boolean;
  userInfo: BasicUserInfo | null;
  idToken: string | null;
  isIdTokenExpired: boolean | null;
  decodedIdToken: DecodedIDTokenPayload | null;
  roles: Role[];
  userPrivileges: number[] | null;
  errorMessage: string | null;
  authFlowState: AuthFlowState;
}
export interface AuthData {
  userInfo: BasicUserInfo;
  idToken: string;
  decodedIdToken: DecodedIDTokenPayload;
}

export type Subsidiary = {
  id: number;
  taxCodeInternalId: number;
  taxCode: string;
  legalName: string;
  code: string;
};

export type SubsidiaryPayload = {
  taxCodeInternalId: number;
  taxCode: string;
  legalName: string;
  code: string;
};

export type SubsidiaryPatchPayload = Partial<SubsidiaryPayload>;

export type Department = {
  id: number;
  employeeDepartment: string;
  engagementCode: string;
  glCodeId: number;
  glCode: string;
  glCodeInternalId: number;
  glAccountName: string;
  costCenter: string | null;
};

export type DepartmentPayload = {
  employeeDepartment: string;
  engagementCode: string;
  glCodeId: number;
};

export type DepartmentPatchPayload = Partial<DepartmentPayload>;

export type GlCode = {
  id: number;
  internalId: number;
  glCode: string;
  glAccountName: string;
  costCenter: string | null;
};

export type ExpenseType = {
  id: number;
  expenseCategoryId: number;
  expenseCategory: string;
  expenseDescription: string | null;
  glCodeId: number;
  glCode: string;
  glCodeInternalId: number;
  glAccountName: string;
  costCenter: string;
  expenseType: string;
  expenseTypeDescription: string | null;
  engagementCodes: string[];
  engagementCodeSuffixes: string[];
  status: string;
};

export type ExpenseTypePayload = {
  expenseCategoryId: number;
  glCodeId: number;
  expenseType: string;
  expenseTypeDescription: string;
  engagementCodes: string[];
  engagementCodeSuffixes: string[];
};

export type ExpenseTypePatchPayload = Partial<ExpenseTypePayload>;

export type ExpenseTypeFilter = {
  expenseCategoryIds?: number[];
  glCodeIds?: number[];
  expenseTypes?: string[];
  engagementCodes?: string[];
  engagementCodeSuffixes?: string[];
  status?: string[];
};

export type ExpenseTypeFilterAutoComplete = {
  expenseCategoryIds: { id: number; label: string }[];
  glCodeIds: { id: number; label: string }[];
  expenseTypes: string[];
  engagementCodes: string[];
  engagementCodeSuffixes: string[];
};

export type CreditCard = {
  id: number;
  ccNumber: string;
  ccProviderCode: string;
  comment: string | null;
  employeeEmail: string;
  leadEmails: string[];
  status: string
};

export type CreditCardPayload = {
  ccNumber: string;
  ccProviderCode: string;
  comment: string;
  employeeEmail: string;
  leadEmails: string[];
};

export type CreditCardPatchPayload = Partial<CreditCardPayload>;

export type GlCodeDropdown = {
  glCodeId: number;
  glCode: string;
};

export type Employees = {
  workEmail: string;
};

export type User = {
  email: string | null;
  image: string;
};
