import { styled, TextField, MenuItem } from "@mui/material";
import { useState, useEffect } from "react";
import { textFieldTheme, theme } from "../../theme";
import { GlCodeDropdown } from "../../utils/types";

const FormTextField = styled(TextField)(textFieldTheme);

export const CustomDropDown: React.FC<{
  label: string;
  id: string;
  initialValue: string | number;
  isRequired: boolean;
  dropdownValues: GlCodeDropdown[];
  onChange: (fieldId: string, enteredValue: string | number) => void;
}> = ({ label, id, onChange, initialValue, isRequired, dropdownValues }) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [focused, setFocused] = useState<boolean>(false);

  const handleInputData = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setInputValue(event.target.value);
    onChange(id, parseInt(event.target.value));
  };

  useEffect(() => {
    setInputValue(initialValue === 0 ? "" : initialValue.toString());
  }, []);

  return (
    <FormTextField
      SelectProps={{
        MenuProps: {
          sx: {
            ".MuiList-root": {
              backgroundColor: theme.palette.secondary.main,
            },
          },
        },
      }}
      margin="dense"
      fullWidth
      id={id}
      label={label}
      select
      variant="outlined"
      required={isRequired}
      value={inputValue}
      error={!inputValue && focused}
      onClick={() => {
        isRequired && setFocused(true);
      }}
      onChange={handleInputData}
    >
      {dropdownValues.map((value) => (
        <MenuItem value={value.glCodeId} key={value.glCodeId}>
          {value.glCode}
        </MenuItem>
      ))}
    </FormTextField>
  );
};
