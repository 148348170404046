import { Box, Button, Stack } from "@mui/material";
import { theme } from "../../theme";
import { ExpenseTypeFilter, ExpenseTypeFilterAutoComplete } from "../../utils/types";
import { CustomAutoComplete } from "../form-fields/CustomAutoComplete";
import { useFetchContext } from "../../utils/context/table-context";
import { useState } from "react";

export const FilterFields: React.FC<{
  filterAutoCompleteData: ExpenseTypeFilterAutoComplete | undefined;
  setFilters: React.Dispatch<React.SetStateAction<ExpenseTypeFilter>>;
  setIsFiltersApplyBtnClicked: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  filters: ExpenseTypeFilter;
  isFiltersLoading: boolean;
  isLoading: boolean;
  className: string;
}> = ({
  filterAutoCompleteData,
  setFilters,
  filters,
  setIsFiltersApplyBtnClicked,
  setIsLoading,
  isLoading,
  isFiltersLoading,
  className,
}) => {
  const { setFetchData } = useFetchContext();
  const [isClearBtnClicked, setIsClearBtnClicked] = useState<boolean>(false);

  const handleItemSelect = (fieldId: string, enteredValue: string[]) => {
    setFilters((prevData) => ({
      ...prevData,
      [fieldId]: enteredValue.filter((value) => value !== null),
    }));
  };

  const handleApplyFilterBtnClick = () => {
    for (const key in filters) {
      if (filters[key as keyof ExpenseTypeFilter]?.length === 0) {
        delete filters[key as keyof ExpenseTypeFilter];
      }
    }
    setIsLoading(true);
    setIsFiltersApplyBtnClicked(true);
    setFetchData(true);
  };

  const handleClearBtnClick = () => {
    setIsClearBtnClicked(true);
    setFilters({});
  };

  return (
    <div className={className}>
      <Stack direction={"row"} marginTop={"25px"}>
        <CustomAutoComplete
          filterAutoCompleteData={filterAutoCompleteData?.expenseCategoryIds ?? []}
          id="expenseCategoryIds"
          label="Expense Category"
          onChange={handleItemSelect}
          isFiltersLoading={isFiltersLoading}
          isClearBtnClicked={isClearBtnClicked}
          setIsClearBtnClicked={setIsClearBtnClicked}
        />
        <Box width={"15px"} />
        <CustomAutoComplete
          filterAutoCompleteData={filterAutoCompleteData?.glCodeIds ?? []}
          id="glCodeIds"
          label="Gl code"
          onChange={handleItemSelect}
          isFiltersLoading={isFiltersLoading}
          isClearBtnClicked={isClearBtnClicked}
          setIsClearBtnClicked={setIsClearBtnClicked}
        />
        <Box width={"15px"} />
        <CustomAutoComplete
          filterAutoCompleteData={filterAutoCompleteData?.expenseTypes ?? []}
          id="expenseTypes"
          label="Expense type"
          onChange={handleItemSelect}
          isFiltersLoading={isFiltersLoading}
          isClearBtnClicked={isClearBtnClicked}
          setIsClearBtnClicked={setIsClearBtnClicked}
        />
      </Stack>
      <Stack direction={"row"}>
        <CustomAutoComplete
          filterAutoCompleteData={filterAutoCompleteData?.engagementCodes ?? []}
          id="engagementCodes"
          label="Engagement code"
          onChange={handleItemSelect}
          isFiltersLoading={isFiltersLoading}
          isClearBtnClicked={isClearBtnClicked}
          setIsClearBtnClicked={setIsClearBtnClicked}
        />
        <Box width={"15px"} />
        <CustomAutoComplete
          filterAutoCompleteData={filterAutoCompleteData?.engagementCodeSuffixes ?? []}
          id="engagementCodeSuffixes"
          label="Engagement suffix"
          onChange={handleItemSelect}
          isFiltersLoading={isFiltersLoading}
          isClearBtnClicked={isClearBtnClicked}
          setIsClearBtnClicked={setIsClearBtnClicked}
        />
        <Box width={"15px"} />
        <CustomAutoComplete
          filterAutoCompleteData={["active", "inactive"]}
          id="status"
          label="Status"
          onChange={handleItemSelect}
          isFiltersLoading={isFiltersLoading}
          isClearBtnClicked={isClearBtnClicked}
          setIsClearBtnClicked={setIsClearBtnClicked}
        />
      </Stack>
      <Stack direction={"row"}>
        <Box>
          <Button
            variant="contained"
            size="small"
            sx={{ backgroundColor: theme.palette.primary.main }}
            onClick={handleApplyFilterBtnClick}
            disabled={isLoading || isFiltersLoading}
          >
            Apply Filters
          </Button>
        </Box>
        <Box width={"10px"} />
        <Box>
          <Button
            variant="contained"
            size="small"
            color="error"
            onClick={handleClearBtnClick}
            disabled={isLoading || isFiltersLoading}
          >
            Clear
          </Button>
        </Box>
      </Stack>
    </div>
  );
};
