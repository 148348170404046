import { useState } from "react";
import { DialogTitle, DialogContent, DialogActions, Button, CircularProgress } from "@mui/material";
import { CustomTextField } from "../form-fields/CustomTextField";
import { SubsidiaryPayload } from "../../utils/types";
import { theme } from "../../theme";

export const SubsidiaryFormContent: React.FC<{
  handleDataUpdate: (dataKey: string, enteredValue: string | number) => void;
  handleFormClose: () => void;
  handleFormSubmit: () => Promise<void>;
  isBtnDisable: boolean;
  isEditForm: boolean;
  data: SubsidiaryPayload;
}> = ({ handleDataUpdate, handleFormClose, handleFormSubmit, isBtnDisable, isEditForm, data }) => {
  const [isDataSubmitted, setIsDataSubmitted] = useState<boolean>();

  const handleSubmitBtnClick = () => {
    setIsDataSubmitted(false);
    handleFormSubmit();
    setIsDataSubmitted(true);
  };

  return (
    <>
      <DialogTitle sx={{ paddingBottom: 4 }}>
        {isEditForm ? "Add New Subsidiary" : "Update Existing Subsidiary"}
      </DialogTitle>
      <div>
        <DialogContent>
          <CustomTextField
            label="Subsidiary Legal Name"
            type="text"
            id="legalName"
            initialValue={data.legalName}
            isRequired={true}
            onChange={handleDataUpdate}
          />
          <CustomTextField
            label="Subsidiary Code"
            type="text"
            id="code"
            initialValue={data.code}
            isRequired={true}
            onChange={handleDataUpdate}
          />
          <CustomTextField
            label="Tax ID"
            type="number"
            id="taxCodeInternalId"
            initialValue={data.taxCodeInternalId}
            isRequired={true}
            onChange={handleDataUpdate}
          />
          <CustomTextField
            label="Tax Code"
            type="text"
            id="taxCode"
            initialValue={data.taxCode}
            isRequired={true}
            onChange={handleDataUpdate}
          />
        </DialogContent>
      </div>
      <DialogActions sx={{ paddingTop: 5 }}>
        <Button onClick={handleFormClose} sx={{ color: theme.palette.text.secondary }} size="large">
          Cancel
        </Button>
        <Button variant="contained" size="large" onClick={handleSubmitBtnClick} disabled={isBtnDisable}>
          {isDataSubmitted && <CircularProgress size={"23px"} sx={{ marginRight: "20px" }} />}
          {isEditForm ? "Submit" : "Update"}
        </Button>
      </DialogActions>
    </>
  );
};
