import { styled, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { textFieldTheme } from "../../theme";
import { creditCardTypes } from "../../utils/constants";
import { isCreditCardValid } from "../../utils/utils";

const FormTextField = styled(TextField)(textFieldTheme);

export const CCNumberValidateTextField: React.FC<{
  label: string;
  type: string;
  id: string;
  initialValue: string;
  isRequired: boolean;
  selectedText: string;
  onChange: (fieldId: string, enteredValue: string | number) => void;
}> = ({ label, type, id, onChange, isRequired, selectedText, initialValue }) => {
  const [inputValue, setInputValue] = useState<string>(initialValue);
  const [prevSelectedText, setPrevSelectedText] = useState<string>("");
  const [focused, setFocused] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);

  useEffect(() => {
    setInputValue(initialValue);
    if (selectedText.length > 0 && isCreditCardValid(selectedText, initialValue)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
    if (prevSelectedText.length > 0) {
      if (prevSelectedText != selectedText) {
        setInputValue("");
        onChange(id, "");
      }
    }
    setPrevSelectedText(selectedText);
  }, [selectedText]);

  const handleInputData = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (selectedText.length > 0 && isCreditCardValid(selectedText, event.target.value)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
    setInputValue(event.target.value);
    onChange(id, event.target.value.trim());
  };

  const handleHelperText = () => {
    if (selectedText != "") {
      return selectedText === creditCardTypes.AMEX ? "Format should be 123-12345" : "Format should be 1234-1234";
    } else {
      return "Please select credit card provider code";
    }
  };

  return (
    <FormTextField
      autoComplete="off"
      margin="dense"
      fullWidth
      id={id}
      label={label}
      type={type}
      variant="outlined"
      required={isRequired}
      value={inputValue}
      helperText={handleHelperText()}
      error={(!inputValue.trim() || !isValid) && focused}
      onBlur={() => {
        isRequired && setFocused(true);
      }}
      onChange={handleInputData}
    ></FormTextField>
  );
};
