import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { Role, AuthState, AuthData, AuthFlowState } from "../../utils/types";
import { initialUserPrivilegeRequest } from "../../config/config";
import { getUserPrivileges } from "../../utils/auth";

const initialState: AuthState = {
  isAuthenticated: false,
  status: "idle",
  mode: "active",
  statusMessage: null,
  userInfo: null,
  idToken: null,
  isIdTokenExpired: null,
  decodedIdToken: null,
  roles: [],
  userPrivileges: null,
  errorMessage: null,
  authFlowState: "start",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    setUserAuthData: (state, action: PayloadAction<AuthData>) => {
      state.userInfo = action.payload.userInfo;
      state.idToken = action.payload.idToken;
      state.decodedIdToken = action.payload.decodedIdToken;
    },
    setStatus: (state, action: PayloadAction<AuthState["status"]>) => {
      state.status = action.payload;
    },
    setStatusMessage: (state, action: PayloadAction<string | null>) => {
      state.statusMessage = action.payload;
    },
    setTokenState: (state) => {
      state.isIdTokenExpired = true;
    },
    setAuthFlowState: (state, action: PayloadAction<AuthFlowState>) => {
      state.authFlowState = action.payload;
    },
    setErrorMessage: (state, action: PayloadAction<string | null>) => {
      state.errorMessage = action.payload;
    },
    checkTokenState: (state) => {
      state.isIdTokenExpired = state.decodedIdToken ? Date.now() >= state.decodedIdToken?.exp * 1000 : null;
    },
    resetStates: (state) => {
      state = {
        isAuthenticated: false,
        status: "idle",
        mode: "active",
        statusMessage: null,
        userInfo: null,
        idToken: null,
        isIdTokenExpired: null,
        decodedIdToken: null,
        roles: [],
        userPrivileges: null,
        errorMessage: null,
        authFlowState: "start",
      };
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(loadPrivileges.fulfilled, (state, action) => {
        state.userPrivileges = action.payload;
        var roles = [];
        // appending UI roles based on user privileges
        if (!initialUserPrivilegeRequest) {
          roles.push(Role.ALL);
        }

        state.roles = roles;
        state.authFlowState = "end";
        state.isAuthenticated = true;
        state.status = "success";
      })
      .addCase(loadPrivileges.rejected, (state) => {
        state.status = "failed";
        state.authFlowState = "e_user_privileges";
        state.errorMessage = "Unable to load user privileges";
        state.isAuthenticated = false;
      });
  },
});

export const loadPrivileges = createAsyncThunk("auth/loadPrivileges", async () => {
  return getUserPrivileges();
});

export const {
  setIsAuthenticated,
  setUserAuthData,
  setStatus,
  setStatusMessage,
  checkTokenState,
  setTokenState,
  resetStates,
  setAuthFlowState,
  setErrorMessage,
} = authSlice.actions;

export const selectIsAuthenticated = (state: RootState) => state.auth.isAuthenticated;
export const selectUserInfo = (state: RootState) => state.auth.userInfo;
export const selectIdToken = (state: RootState) => state.auth.idToken;

export const selectStatus = (state: RootState) => state.auth.status;
export const selectRoles = (state: RootState) => state.auth.roles;
export const selectStatusMessage = (state: RootState) => state.auth.statusMessage;
export const isIdTokenExpired = (state: RootState) => state.auth.isIdTokenExpired;

export default authSlice.reducer;
