import { styled, TextField } from "@mui/material";
import { useState } from "react";
import { textFieldTheme } from "../../theme";

const FormTextField = styled(TextField)(textFieldTheme);

export const CustomTextField: React.FC<{
  label: string;
  type: string;
  id: string;
  initialValue: string | number;
  isRequired: boolean;
  onChange: (fieldId: string, enteredValue: string | number) => void;
}> = ({ label, type, id, onChange, initialValue, isRequired }) => {
  const [inputValue, setInputValue] = useState<string>(initialValue === 0 ? "" : initialValue.toString());
  const [focused, setFocused] = useState<boolean>(false);

  const handleInputData = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setInputValue(event.target.value);

    if (type === "number") {
      if (isNaN(parseInt(event.target.value))) {
        onChange(id, 0);
      } else {
        onChange(id, parseInt(event.target.value));
      }
    } else {
      onChange(id, event.target.value.trim());
    }
  };

  return (
    <FormTextField
      autoComplete="off"
      margin="dense"
      fullWidth
      id={id}
      label={label}
      type={type}
      variant="outlined"
      required={isRequired}
      value={inputValue}
      error={!inputValue.trim() && focused}
      onBlur={() => {
        isRequired && setFocused(true);
      }}
      onChange={handleInputData}
    ></FormTextField>
  );
};
