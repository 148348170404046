import { Navigate, createBrowserRouter } from "react-router-dom";
import { Root } from "./pages/Root";
import { Subsidiaries } from "./pages/Subsidiaries";
import { Departments } from "./pages/Departments";
import Error from "./pages/404";
import { ExpenseTypes } from "./pages/ExpenseTypes";
import { CreditCards } from "./pages/CreditCards";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      { element: <Navigate to={"/subsidiaries"} />, index: true },
      { path: "subsidiaries", element: <Subsidiaries /> },
      { path: "departments", element: <Departments /> },
      { path: "expense-types", element: <ExpenseTypes /> },
      { path: "credit-cards", element: <CreditCards /> },
    ],
    errorElement: Error(),
  },
]);
