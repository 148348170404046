import MaintenancePage from "../pages/Maintenance";
import { RootState, useAppSelector } from "../slices/store";
import { RouterProvider } from "react-router-dom";
import { router } from "../routes";
import PreLoader from "../components/PreLoader";
import ErrorHandler from "../components/ErrorHandler";

const AppHandler: React.FC = () => {
  const auth = useAppSelector((state: RootState) => state.auth);

  return (
    <>
      <>
        {auth.status === "loading" && (
          <>
            <PreLoader isLoading={true} message={auth.statusMessage}></PreLoader>
          </>
        )}
        {auth.status === "success" && auth.mode === "active" && <RouterProvider router={router} />}
        {auth.status === "success" && auth.mode === "maintenance" && <MaintenancePage />}

        {auth.status === "failed" && <ErrorHandler message={auth.errorMessage} />}
      </>
    </>
  );
};

export default AppHandler;
