import { Button, Stack, ToggleButton, Typography } from "@mui/material";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { FormDialog } from "../dialogs/FormDialog";
import { tabLabels } from "../../utils/constants";
import { useTabContext } from "../../utils/context/tab-context";
import Filter from "@mui/icons-material/FilterAlt";

export const CardHeader: React.FC<{
  btnLabel: string;
}> = ({ btnLabel }) => {
  const [openForm, setOpenForm] = useState(false);
  const { selectedTab, isFilterBtnSelected, setIsFilterBtnSelected } = useTabContext();

  const handleFormDialogOpen = () => {
    setOpenForm(true);
  };

  const handleFormDialogClose = () => {
    setOpenForm(false);
  };

  return (
    <Stack direction={"row"} display={"flex"} justifyContent={"space-between"} paddingTop={1}>
      <Typography fontSize={25} fontWeight={600} color={"white"}>
        {tabLabels[selectedTab]}
      </Typography>
      <Stack direction={"row"}>
        <Button
          color="primary"
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleFormDialogOpen}
          sx={{ paddingY: "10px" }}
          size="small"
        >
          {btnLabel}
        </Button>
        {selectedTab === "expenseTypes" && (
          <ToggleButton
            value="filter"
            selected={isFilterBtnSelected}
            onChange={() => {
              setIsFilterBtnSelected!(!isFilterBtnSelected);
            }}
            sx={{ marginLeft: "20px" }}
          >
            <Filter />
            <Typography textTransform={"capitalize"} paddingLeft={"10px"} fontSize={14}>
              {isFilterBtnSelected ? "Hide Filters" : "Show Filters"}
            </Typography>
            <Typography />
          </ToggleButton>
        )}
      </Stack>
      {openForm && <FormDialog open={openForm} handleFormDialogClose={handleFormDialogClose} />}
    </Stack>
  );
};
