import { Sidebar } from "../components/Sidebar";
import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import { TopStickyBar } from "../components/top-navbar/TopStickyBar";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useAppAuthContext } from "../context/AuthContext";
import ForbiddenImage from "../assets/forbidden.svg";
import { ROLES } from "../config/config";

export const Root = () => {
  const [isUserImageFetching, setIsUserImageFetching] = useState<boolean>(true);
  const [isUserHavePrivileges, setIsUserHavePrivileges] = useState<boolean>();
  const authContext = useAppAuthContext();

  useEffect(() => {
    if (authContext.user?.groups != null) {
      if (ROLES.length === 0) {
        setIsUserHavePrivileges(true);
      } else if (ROLES.some((element) => (authContext.user?.groups).includes(element))) {
        setIsUserHavePrivileges(true);
      } else {
        setIsUserHavePrivileges(false);
      }
    }
  }, [authContext]);

  return isUserHavePrivileges === undefined ? (
    <CircularProgress />
  ) : isUserHavePrivileges ? (
    <Stack direction={"row"}>
      <Sidebar />
      <Stack sx={{ height: "100vh", width: "100vw" }}>
        <TopStickyBar setIsUserImageFetching={setIsUserImageFetching} />
        <Outlet context={isUserImageFetching} />
      </Stack>
    </Stack>
  ) : (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100vw",
        height: "100vh",
        backgroundColor: "grey",
      }}
    >
      <Box
        sx={{
          border: "2px solid #ccc",
          paddingX: "100px",
          paddingY: "50px",
          borderRadius: "10px",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src={ForbiddenImage} height={150} alt="Forbidden Image" />
        <Box height={50} />
        <Typography fontSize={20}>You do not have sufficient privileges to access this content</Typography>
        <Box height={30} />
        <Button color="primary" variant="contained" onClick={authContext.appSignOut}>
          Log out
        </Button>
      </Box>
    </div>
  );
};
