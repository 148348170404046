import { comboFormIdPairs, creditCardTypes, optionalFormIds, tabLabels } from "./constants";
import {
  isCreditCard,
  isCreditCardPayload,
  isDepartment,
  isDepartmentPayload,
  isExpenseType,
  isExpenseTypePayload,
  isSubsidiary,
  isSubsidiaryPayload,
} from "./typeGuards";
import {
  Department,
  Subsidiary,
  DepartmentPatchPayload,
  SubsidiaryPatchPayload,
  SubsidiaryPayload,
  DepartmentPayload,
  ExpenseType,
  ExpenseTypePayload,
  ExpenseTypePatchPayload,
  CreditCard,
  CreditCardPayload,
  CreditCardPatchPayload,
} from "./types";

export const setInitialData = (
  selectedTab: keyof typeof tabLabels,
  data?: Subsidiary | Department | ExpenseType | CreditCard
): SubsidiaryPayload | DepartmentPayload | ExpenseTypePayload | CreditCardPayload => {
  if (selectedTab === "subsidiaries") {
    data = data as Subsidiary | undefined;
    return {
      taxCodeInternalId: data ? data.taxCodeInternalId : 0,
      taxCode: data ? data.taxCode : "",
      legalName: data ? data.legalName : "",
      code: data ? data.code : "",
    };
  }
  if (selectedTab === "departments") {
    data = data as Department | undefined;
    return {
      employeeDepartment: data ? data.employeeDepartment : "",
      engagementCode: data ? data.engagementCode : "",
      glCodeId: data ? data.glCodeId : 0,
    };
  }
  if (selectedTab === "expenseTypes") {
    data = data as ExpenseType | undefined;
    return {
      expenseCategoryId: data ? data.expenseCategoryId : 0,
      glCodeId: data ? data.glCodeId : 0,
      expenseType: data ? data.expenseType : "",
      expenseTypeDescription: data ? (data.expenseTypeDescription === null ? "" : data.expenseTypeDescription) : "",
      engagementCodes: data ? data.engagementCodes : [],
      engagementCodeSuffixes: data ? data.engagementCodeSuffixes : [],
    };
  }
  if (selectedTab === "creditCards") {
    data = data as CreditCard | undefined;
    return {
      ccNumber: data ? data.ccNumber : "",
      ccProviderCode: data ? data.ccProviderCode : "",
      employeeEmail: data ? data.employeeEmail : "",
      leadEmails: data ? data.leadEmails : [],
      comment: data ? (data.comment === null ? "" : data.comment) : "",
    };
  }
  throw new Error("Data validation failed: Invalid selectedTab");
};

export const isCreditCardValid = (ccProviderCode: string, ccNumber: string) => {
  if (ccProviderCode === creditCardTypes.AMEX) {
    const regexValidation: RegExp = /^\d{3}-\d{5}$/;
    return regexValidation.test(ccNumber);
  } else if (ccProviderCode === creditCardTypes.SVB) {
    const regexValidation: RegExp = /^\d{4}-\d{4}$/;
    return regexValidation.test(ccNumber);
  } else {
    throw new Error("Data validation failed: Invalid credit card provider code");
  }
};

export const isAllFieldsFilled = (
  selectedTab: keyof typeof tabLabels,
  formData: SubsidiaryPayload | DepartmentPayload | ExpenseTypePayload | CreditCardPayload
) => {
  console.log("Form Data: ", formData);
  for (const key of Object.keys(formData)) {
    if (
      !optionalFormIds[selectedTab].includes(
        key as keyof SubsidiaryPayload | keyof DepartmentPayload | keyof ExpenseTypePayload
      )
    ) {
      if (isSubsidiaryPayload(formData)) {
        if (formData[key as keyof SubsidiaryPayload] === "" || formData[key as keyof SubsidiaryPayload] === 0) {
          return false;
        }
      } else if (isDepartmentPayload(formData)) {
        if (formData[key as keyof DepartmentPayload] === "" || formData[key as keyof DepartmentPayload] === 0) {
          return false;
        }
      } else if (isExpenseTypePayload(formData)) {
        for (let comboFormIdPair of comboFormIdPairs[selectedTab]) {
          if (comboFormIdPair.includes(key as keyof ExpenseTypePayload)) {
            let comboValue1: string | number | string[] = formData[comboFormIdPair[0] as keyof ExpenseTypePayload];
            let comboValue2: string | number | string[] = formData[comboFormIdPair[1] as keyof ExpenseTypePayload];
            if (Array.isArray(comboValue1) && Array.isArray(comboValue2)) {
              if (comboValue1.every((element) => element === "") && comboValue2.every((element) => element === "")) {
                return false;
              }
            }
          } else {
            let value: string | number | string[] = formData[key as keyof ExpenseTypePayload];
            if (Array.isArray(value)) {
              if (value.every((element) => element === "")) {
                return false;
              }
            }
            if (value === "" || value === 0) {
              return false;
            }
          }
        }
      } else if (isCreditCardPayload(formData)) {
        const value = formData[key as keyof CreditCardPayload];
        const ccProviderCode = formData["ccProviderCode"];
        const ccNumber = formData["ccNumber"];

        if (
          value === "" ||
          value.length === 0 ||
          (ccProviderCode.length > 0 && !isCreditCardValid(ccProviderCode, ccNumber))
        ) {
          return false;
        }
      } else {
        throw new Error("Data validation failed: Missing Typeguard");
      }
    }
  }
  return true;
};

const replaceNullValuesWithEmptyString = (obj: Record<string, any>): void => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] === null) {
      obj[key] = "";
    }
  }
};

export const areDataEqual = (
  data: Subsidiary | Department | ExpenseType | CreditCard,
  formData: SubsidiaryPayload | DepartmentPayload | ExpenseTypePayload | CreditCardPayload
) => {
  replaceNullValuesWithEmptyString(data);

  if (isSubsidiary(data) && isSubsidiaryPayload(formData)) {
    return Object.keys(formData).every(
      (key) => data[key as keyof Subsidiary] === formData[key as keyof SubsidiaryPayload]
    );
  }
  if (isDepartment(data) && isDepartmentPayload(formData)) {
    return Object.keys(formData).every(
      (key) => data[key as keyof Department] === formData[key as keyof DepartmentPayload]
    );
  }
  if (isExpenseType(data) && isExpenseTypePayload(formData)) {
    return Object.keys(formData).every((key) => {
      if (Array.isArray(formData[key as keyof ExpenseTypePayload])) {
        return (
          JSON.stringify((data[key as keyof ExpenseType] as string[]).slice().sort()) ===
          JSON.stringify((formData[key as keyof ExpenseTypePayload] as string[]).slice().sort())
        );
      } else {
        return data[key as keyof ExpenseType] == formData[key as keyof ExpenseTypePayload];
      }
    });
  }
  if (isCreditCard(data) && isCreditCardPayload(formData)) {
    return Object.keys(formData).every((key) => {
      if (Array.isArray(formData[key as keyof CreditCardPayload])) {
        return (
          JSON.stringify((data[key as keyof CreditCard] as string[]).slice()) ===
          JSON.stringify((formData[key as keyof CreditCardPayload] as string[]).slice())
        );
      } else {
        return data[key as keyof CreditCard] == formData[key as keyof CreditCardPayload];
      }
    });
  }
  throw new Error("Data validation failed: Missing Typeguard or received unexpected data types");
};

export const getUpdatedData = (
  data: Subsidiary | Department | ExpenseType | CreditCard,
  formData: SubsidiaryPayload | DepartmentPayload | ExpenseTypePayload | CreditCardPayload
): SubsidiaryPatchPayload | DepartmentPatchPayload | ExpenseTypePatchPayload | CreditCardPatchPayload => {
  const updatedFields: Record<string, string | string[] | number> = {};

  if (isSubsidiary(data) && isSubsidiaryPayload(formData)) {
    Object.keys(formData).forEach((key) => {
      if (data[key as keyof Subsidiary] !== formData[key as keyof SubsidiaryPayload]) {
        updatedFields[key as keyof SubsidiaryPatchPayload] = formData[key as keyof SubsidiaryPayload];
      }
    });
    return updatedFields;
  }
  if (isDepartment(data) && isDepartmentPayload(formData)) {
    Object.keys(formData).forEach((key) => {
      if (data[key as keyof Department] !== formData[key as keyof DepartmentPayload]) {
        updatedFields[key as keyof DepartmentPatchPayload] = formData[key as keyof DepartmentPayload];
      }
    });
    return updatedFields;
  }
  if (isExpenseType(data) && isExpenseTypePayload(formData)) {
    Object.keys(formData).forEach((key) => {
      if (data[key as keyof ExpenseType] !== formData[key as keyof ExpenseTypePayload]) {
        updatedFields[key as keyof ExpenseTypePatchPayload] = formData[key as keyof ExpenseTypePayload];
      }
    });
    return updatedFields;
  }
  if (isCreditCard(data) && isCreditCardPayload(formData)) {
    Object.keys(formData).forEach((key) => {
      if (data[key as keyof CreditCard] !== formData[key as keyof CreditCardPayload]) {
        updatedFields[key as keyof CreditCardPatchPayload] = formData[key as keyof CreditCardPayload];
      }
    });
    return updatedFields;
  }
  throw new Error("Data validation failed: Missing Typeguard or received unexpected data types");
};
