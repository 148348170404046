import React, { useContext, useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useAuthContext, BasicUserInfo, SecureApp } from "@asgardeo/auth-react";
import { useAppDispatch } from "../slices/store";
import { loadPrivileges, setUserAuthData } from "../slices/authSlice";
import { ApiService } from "../utils/apiService";
import PreLoader from "../components/PreLoader";
import StatusWithAction from "../components/StatusWithAction";
import { theme } from "../theme";

type AuthContextType = {
  revokeToken: () => void;
  appSignIn: () => void;
  appSignOut: () => void;
  user: BasicUserInfo | null;
};

const timeout = 1800_000;
const promptBeforeIdle = 4_000;

const AuthContext = React.createContext<AuthContextType>({} as AuthContextType);

const AppAuthProvider = (props: { children: React.ReactNode }) => {
  const [user, setUser] = useState<BasicUserInfo | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [appState, setAppState] = useState<"logout" | "active" | "loading">("loading");

  const dispatch = useAppDispatch();

  const onPrompt = () => {
    appState === "active" && setOpen(true);
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500,
  });

  const {
    signIn,
    getIDToken,
    signOut,
    getDecodedIDToken,
    getAccessToken,
    getBasicUserInfo,
    revokeAccessToken,
    refreshAccessToken,
    state,
  } = useAuthContext();

  const refreshToken = () => {
    return new Promise<{ idToken: string }>((resolve) => {
      refreshAccessToken()
        .then(async (res) => {
          const idToken = await getIDToken();
          localStorage.setItem("cp-app-token", idToken);
          resolve({ idToken: idToken });
        })
        .catch((error) => {
          appSignOut();
        });
    });
  };

  useEffect(() => {
    var appStatus = localStorage.getItem("internal-app-state");

    if (!localStorage.getItem("internal-app-redirect-url")) {
      localStorage.setItem("internal-app-redirect-url", window.location.href.replace(window.location.origin, ""));
    }

    if (appStatus && appStatus === "logout") {
      setAppState("logout");
    } else {
      setAppState("active");
    }
  }, []);

  useEffect(() => {
    if (appState === "active") {
      if (state.isAuthenticated) {
        Promise.all([getBasicUserInfo(), getIDToken(), getDecodedIDToken()]).then(
          async ([userInfo, idToken, decodedIdToken]) => {
            dispatch(
              setUserAuthData({
                userInfo: userInfo,
                idToken: idToken,
                decodedIdToken: decodedIdToken,
              })
            );

            setUser(userInfo);
            new ApiService(idToken, refreshToken);
            dispatch(loadPrivileges());
          }
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appState, state.isAuthenticated]);

  const revokeToken = () => {
    revokeAccessToken();
  };

  const appSignOut = async () => {
    setAppState("loading");
    localStorage.setItem("internal-app-state", "logout");
    revokeToken();
    await signOut();
    setAppState("logout");
  };

  const appSignIn = async () => {
    setAppState("active");
    localStorage.setItem("internal-app-state", "active");
  };

  const authContext: AuthContextType = {
    revokeToken: revokeToken,
    appSignIn: appSignIn,
    appSignOut: appSignOut,
    user: user,
  };

  return (
    <>
      {appState === "loading" ? (
        <PreLoader isLoading={true} message="" />
      ) : (
        <>
          <Dialog
            open={open}
            onClose={() => setOpen(false)}
            PaperProps={{
              style: {
                backgroundColor: theme.palette.secondary.main,
                backgroundImage: "none",
              },
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Are you still there?"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                It looks like you've been inactive for a while. Would you like to continue?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpen(false)}>Continue</Button>
              <Button onClick={() => appSignOut()}>Logout</Button>
            </DialogActions>
          </Dialog>
          {appState === "active" ? (
            <AuthContext.Provider value={authContext}>
              <SecureApp>{props.children}</SecureApp>
            </AuthContext.Provider>
          ) : (
            <StatusWithAction action={() => appSignIn()} />
          )}
        </>
      )}
    </>
  );
};

const useAppAuthContext = (): AuthContextType => useContext(AuthContext);

export { useAppAuthContext };

export default AppAuthProvider;
