import { CustomCard } from "../components/cards/CustomCard";
import { CardHeader } from "../components/cards/CardHeader";
import { useState } from "react";
import { CustomTable } from "../components/data-table/CustomTable";
import { TableContext } from "../utils/context/table-context";
import { TabContext } from "../utils/context/tab-context";
import { Stack } from "@mui/material";
import { useOutletContext } from "react-router-dom";

export const Departments = () => {
  const [fetchData, setFetchData] = useState<boolean>(true);
  const isUserImageFetching: boolean = useOutletContext();

  return (
    <TableContext.Provider value={{ fetchData, setFetchData, isUserImageFetching }}>
      <CustomCard>
        <TabContext.Provider value={{ selectedTab: "departments" }}>
          <Stack height={"100%"}>
            <CardHeader btnLabel={"Add New Department"} />
            <CustomTable />
          </Stack>
        </TabContext.Provider>
      </CustomCard>
    </TableContext.Provider>
  );
};
