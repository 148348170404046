import { createContext, useContext } from "react";

export const TableContext = createContext<
  | {
      fetchData: boolean;
      setFetchData: React.Dispatch<React.SetStateAction<boolean>>;
      isUserImageFetching: boolean;
    }
  | undefined
>(undefined);

export const useFetchContext = () => {
  const shouldFetchData = useContext(TableContext);

  if (shouldFetchData === undefined) {
    throw Error("useFetch Context must be used with a CustomCardContext");
  }
  return shouldFetchData;
};
