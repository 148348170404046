import {
  Box,
  Button,
  Card,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { ExpenseTypePayload } from "../../utils/types";
import { useState } from "react";
import { CustomAutoCompleteTextField } from "../form-fields/CustomAutoCompleteTextField";
import { useTabContext } from "../../utils/context/tab-context";
import { theme } from "../../theme";
import { CustomTextField } from "../form-fields/CustomTextField";

export const ExpenseTypeFormContent: React.FC<{
  handleDataUpdate: (dataKey: string, enteredValue: string | number | string[]) => void;
  handleFormClose: () => void;
  handleFormSubmit: () => Promise<void>;
  isBtnDisable: boolean;
  isEditForm: boolean;
  data: ExpenseTypePayload;
}> = ({ handleFormClose, handleFormSubmit, handleDataUpdate, isBtnDisable, isEditForm, data }) => {
  const [isDataSubmitted, setIsDataSubmitted] = useState<boolean>();
  const { contextFilters } = useTabContext();
  let initialExpenseCategoryId: {
    id: number;
    label: string;
  } = { id: 0, label: "" };
  let initialGlCodeId: {
    id: number;
    label: string;
  } = { id: 0, label: "" };

  const handleSubmitBtnClick = () => {
    setIsDataSubmitted(false);
    handleFormSubmit();
    setIsDataSubmitted(true);
  };

  contextFilters?.expenseCategoryIds.map((expenseCategoryId) => {
    if (data.expenseCategoryId === expenseCategoryId.id) {
      initialExpenseCategoryId = expenseCategoryId;
    }
  });
  contextFilters?.glCodeIds.map((glCodeId) => {
    if (data.glCodeId === glCodeId.id) {
      initialGlCodeId = glCodeId;
    }
  });

  return (
    <div>
      <DialogTitle sx={{ paddingBottom: 4 }}>
        {isEditForm ? "Add New Expense Type" : "Update Existing Expense Type"}
      </DialogTitle>
      <div style={{ overflowY: "auto", height: "60vh" }}>
        <DialogContent>
          <CustomAutoCompleteTextField
            id="expenseCategoryId"
            label="Expense Category"
            initialValue={data.expenseCategoryId === 0 ? null : initialExpenseCategoryId}
            autoCompleteData={contextFilters?.expenseCategoryIds ?? []}
            onChange={handleDataUpdate}
            isMultiple={false}
            isRequired={true}
            dataType="number"
            freeForm={false}
          />
          <CustomAutoCompleteTextField
            id="glCodeId"
            label="Gl Code"
            initialValue={data.glCodeId === 0 ? null : initialGlCodeId}
            autoCompleteData={contextFilters?.glCodeIds ?? []}
            onChange={handleDataUpdate}
            isMultiple={false}
            isRequired={true}
            dataType="number"
            freeForm={false}
          />
          <CustomAutoCompleteTextField
            id="expenseType"
            label="Expense Type"
            initialValue={data.expenseType}
            autoCompleteData={contextFilters?.expenseTypes ?? []}
            onChange={handleDataUpdate}
            isMultiple={false}
            isRequired={true}
            dataType="string"
            freeForm={true}
          />
          <CustomTextField
            label="Expense Type Description"
            type="text"
            id="expenseTypeDescription"
            initialValue={data.expenseTypeDescription}
            isRequired={false}
            onChange={handleDataUpdate}
          />
          <Box height={"10px"} />
          <Card variant="outlined" sx={{ backgroundColor: "#363B47", paddingX: "15px", paddingY: "12px" }}>
            <Typography color={theme.palette.warning.light} fontSize={14}>
              Please fill in at least one of the following fields
            </Typography>
            <Box height={"25px"} />
            <CustomAutoCompleteTextField
              id="engagementCodes"
              label="Engagement Codes"
              initialValue={data.engagementCodes}
              autoCompleteData={contextFilters?.engagementCodes ?? []}
              onChange={handleDataUpdate}
              isMultiple={true}
              isRequired={false}
              dataType="string"
              freeForm={true}
            />
            <CustomAutoCompleteTextField
              id="engagementCodeSuffixes"
              label="Engagement Code Suffixes"
              initialValue={data.engagementCodeSuffixes}
              autoCompleteData={contextFilters?.engagementCodeSuffixes ?? []}
              onChange={handleDataUpdate}
              isMultiple={true}
              isRequired={false}
              dataType="string"
              freeForm={true}
            />
          </Card>
        </DialogContent>
      </div>
      <DialogActions sx={{ paddingTop: 5 }}>
        <Button onClick={handleFormClose} sx={{ color: theme.palette.text.secondary }} size="large">
          Cancel
        </Button>
        <Button variant="contained" size="large" onClick={handleSubmitBtnClick} disabled={isBtnDisable}>
          {isDataSubmitted && <CircularProgress size={"23px"} sx={{ marginRight: "20px" }} />}
          {isEditForm ? "Submit" : "Update"}
        </Button>
      </DialogActions>
    </div>
  );
};
