import { useState } from "react";
import { TableContext } from "../utils/context/table-context";
import { CustomCard } from "../components/cards/CustomCard";
import { TabContext } from "../utils/context/tab-context";
import { Stack } from "@mui/material";
import { CustomTable } from "../components/data-table/CustomTable";
import { CardHeader } from "../components/cards/CardHeader";
import { useOutletContext } from "react-router-dom";
import { ExpenseTypeFilterAutoComplete } from "../utils/types";

export const ExpenseTypes = () => {
  const [fetchData, setFetchData] = useState<boolean>(false);
  const [isFilterBtnSelected, setIsFilterBtnSelected] = useState<boolean>(true);
  const [contextFilters, setContextFilters] = useState<ExpenseTypeFilterAutoComplete>();
  const isUserImageFetching: boolean = useOutletContext();

  return (
    <TableContext.Provider value={{ fetchData, setFetchData, isUserImageFetching }}>
      <CustomCard>
        <TabContext.Provider
          value={{
            selectedTab: "expenseTypes",
            contextFilters,
            setContextFilters,
            isFilterBtnSelected,
            setIsFilterBtnSelected,
          }}
        >
          <Stack height={"100%"} width={"100%"}>
            <CardHeader btnLabel={"Add New Expense Type"} />
            <CustomTable />
          </Stack>
        </TabContext.Provider>
      </CustomCard>
    </TableContext.Provider>
  );
};
