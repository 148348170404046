import { createTheme } from "@mui/material";
import type {} from "@mui/x-data-grid/themeAugmentation";
import "./app.css";

export const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#FF7300",
      light: "rgba(255, 115, 0, 0.10)",
      contrastText: "white",
    },
    secondary: {
      main: "#292E3C",
      light: "#414652",
    },
    text: {
      primary: "#BFC1CA",
      secondary: "#D1D3D9",
    },
    background: {
      default: "#1F2128",
      paper: "#3E4350",
    },
  },
  typography: {
    fontFamily: ["Montserrat", "sans-serif"].join(","),
  },
  components: {
    MuiInputLabel: {
      defaultProps: {
        sx: {
          fontSize: 14,
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        sx: {
          fontSize: 14,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        FormHelperTextProps: {
          style: {
            marginLeft: "0",
          },
        },
      },
    },
  },
});

export const textFieldTheme = {
  marginBottom: 20,
  marginTop: 0,
  "& label, & label.Mui-focused": {
    color: "#B6B8C2",
  },
  "& label.Mui-error": {
    color: "#DF272E",
    fontWeight: "600",
  },
  "& .MuiSelect-icon": {
    color: "#5A5E6A",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#5A5E6A",
      borderWidth: 2,
    },
    "&.Mui-focused .MuiSelect-icon": {
      color: "#B6B8C2",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#B6B8C2",
      borderWidth: 2,
    },
    "&.Mui-error fieldset": {
      borderColor: "#DF272E",
    },
    "&.Mui-error .MuiSelect-icon": {
      color: "#DF272E",
    },
  },
};

export const tableTheme = createTheme({
  ...theme,
  palette: {
    ...theme.palette,
    primary: {
      main: "#FFFFFF",
    },
    background: {
      paper: "#3E4350",
    },
  },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: "none",
          "& .MuiDataGrid-cell:focus": {
            outline: "none",
          },
          "& .MuiDataGrid-cell:focus-within": {
            outline: "none",
          },
          "& .MuiDataGrid-columnHeader:focus": {
            outline: "none",
          },
          "& .MuiDataGrid-columnHeader-within": {
            outline: "none",
          },
        },
      },
    },
  },
});
