import { GridColDef } from "@mui/x-data-grid";
import {
  CreditCard,
  CreditCardPayload,
  Department,
  DepartmentPayload,
  ExpenseType,
  ExpenseTypePayload,
  Subsidiary,
  SubsidiaryPayload,
} from "./types";

export enum tabLabels {
  subsidiaries = "Subsidiaries",
  departments = "Departments",
  expenseTypes = "Expense Types",
  creditCards = "Credit Cards",
}

export enum tooltips {
  glCodeTooltip,
  expenseTypeTooltip,
}

export enum creditCardTypes {
  AMEX = "AMEX",
  SVB = "SVB",
}

enum glCodeTooltip {
  glCodeInternalId,
  glAccountName,
  costCenter,
}

enum expenseTypeTooltip {
  expenseTypeDescription,
}

export const tabUrls: Record<keyof typeof tabLabels, string> = {
  subsidiaries: "/subsidiaries",
  departments: "/departments",
  expenseTypes: "/expense-types",
  creditCards: "/credit-cards",
};

export const endpoints: Record<keyof typeof tabLabels, string> = {
  subsidiaries: "subsidiaries",
  departments: "departments",
  expenseTypes: "expense-types",
  creditCards: "credit-cards",
};

export const filterEndpoints: Record<string, string> = {
  expenseTypes: "search-expense-types",
};

export const subsidiaryDataTableColumns: GridColDef[] = [
  { field: "legalName", headerName: "Subsidiary Legal Name", flex: 4 },
  { field: "code", headerName: "Subsidiary Code", flex: 3 },
  { field: "taxCodeInternalId", headerName: "Tax ID", flex: 1 },
  { field: "taxCode", headerName: "Tax Code", flex: 2 },
];

export const departmentDataTableColumns: GridColDef[] = [
  { field: "employeeDepartment", headerName: "Employee Department", flex: 4 },
  { field: "engagementCode", headerName: "Engagement Code", flex: 2 },
  { field: "glCode", headerName: "GL Code", flex: 2 },
];

export const expenseTypesDataTableColumns: GridColDef[] = [
  { field: "expenseCategory", headerName: "Expense Category", flex: 2 },
  { field: "glCode", headerName: "Gl Code", flex: 1.5 },
  { field: "expenseType", headerName: "Expense Type", flex: 2.5 },
  { field: "engagementCodes", headerName: "Engagement Code", flex: 2 },
  { field: "engagementCodeSuffixes", headerName: "Engagement Code Suffix", flex: 2 },
  { field: "status", headerName: "Status", flex: 1 },
];

export const creditCardsDataTableColumns: GridColDef[] = [
  { field: "ccNumber", headerName: "CC Number", flex: 1.5 },
  { field: "ccProviderCode", headerName: "CC Provider Code", flex: 1.5 },
  { field: "employeeEmail", headerName: "Employee Email", flex: 2.2 },
  { field: "leadEmails", headerName: "Lead Email", flex: 2.2 },
  { field: "comment", headerName: "Comment", flex: 2 },
  { field: "status", headerName: "Status", flex: 1.2 },
];

export const tableColumns: Record<keyof typeof tabLabels, GridColDef[]> = {
  subsidiaries: subsidiaryDataTableColumns,
  departments: departmentDataTableColumns,
  expenseTypes: expenseTypesDataTableColumns,
  creditCards: creditCardsDataTableColumns,
};

export const optionalFormIds: Record<
  keyof typeof tabLabels,
  (keyof SubsidiaryPayload | keyof DepartmentPayload | keyof ExpenseTypePayload | keyof CreditCardPayload)[]
> = {
  subsidiaries: [],
  departments: [],
  expenseTypes: ["expenseTypeDescription"],
  creditCards: ["comment"],
};

export const comboFormIdPairs: Record<
  keyof typeof tabLabels,
  (keyof SubsidiaryPayload | keyof DepartmentPayload | keyof ExpenseTypePayload)[][]
> = {
  subsidiaries: [],
  departments: [],
  expenseTypes: [["engagementCodes", "engagementCodeSuffixes"]],
  creditCards: [],
};

export const initialSortColumn: Record<
  keyof typeof tabLabels,
  keyof Subsidiary | keyof Department | keyof ExpenseType | keyof CreditCard
> = {
  subsidiaries: "legalName",
  departments: "employeeDepartment",
  expenseTypes: "expenseType",
  creditCards: "employeeEmail",
};

export const tablesWithFilters: (keyof typeof tabLabels)[] = ["expenseTypes"];

export const glCodeTooltipFieldsWithId: Record<keyof typeof glCodeTooltip, string> = {
  glCodeInternalId: "Internal ID",
  glAccountName: "Account Name",
  costCenter: "Cost Center",
};

export const expenseTypeTooltipFieldsWithId: Record<keyof typeof expenseTypeTooltip, string> = {
  expenseTypeDescription: "Expense Type Description",
};

export const SIDEBAR_WIDTH = 220;

export const SUPPORT_URL =
  "https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=internal-apps-group@wso2.com&su=[HELP]%20WSO2%20Finance%20Master%20Data%20App";
