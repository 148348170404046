import { Card } from "@mui/material";
import { SIDEBAR_WIDTH } from "../../utils/constants";

export const CustomCard: React.FC<{ children: React.ReactNode }> = (props) => {
  return (
    <Card
      variant="outlined"
      sx={{
        borderColor: "#3E4350",
        margin: "20px",
        marginLeft: `${SIDEBAR_WIDTH + 20}px`,
        bgcolor: "secondary.main",
        paddingX: "30px",
        paddingTop: "20px",
        borderRadius: "15px",
        height: "100%",
        bottom: "100px",
      }}
    >
      {props.children}
    </Card>
  );
};
