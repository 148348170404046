import { TableCell, IconButton, Skeleton, ThemeProvider, Chip, Typography, Box, CircularProgress } from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect, useState } from "react";
import { TooltipCell } from "./TooltipCell";
import {
  CreditCard,
  Department,
  ExpenseType,
  ExpenseTypeFilter,
  ExpenseTypeFilterAutoComplete,
  Subsidiary,
} from "../../utils/types";
import { getData, getFilterAutoCompleteData, getFilteredData } from "../../utils/apiService";
import { initialSortColumn, tableColumns, tablesWithFilters } from "../../utils/constants";
import { useFetchContext } from "../../utils/context/table-context";
import { useSnackbar } from "notistack";
import { useTabContext } from "../../utils/context/tab-context";
import { FormDialog } from "../dialogs/FormDialog";
import { DeleteConfirmationDialog } from "../dialogs/DeleteConfirmationDialog";
import { tableTheme, theme } from "../../theme";
import "./CustomTable.css";
import { FilterFields } from "./FilterFields";
import { SkeletonTable } from "./SkeletonTable";
import noFilterImg from "../../../src/assets/no-filter.svg";

export const CustomTable: React.FC<{}> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isFiltersLoading, setIsFiltersLoading] = useState<boolean>(true);
  const [isFiltersApplyBtnClicked, setIsFiltersApplyBtnClicked] = useState<boolean>();
  const [tableData, setTableData] = useState<Subsidiary[] | Department[] | ExpenseType[] | CreditCard[]>([]);
  const [filters, setFilters] = useState<ExpenseTypeFilter>({});
  const [filterAutoCompleteData, setFilterAutoCompleteData] = useState<ExpenseTypeFilterAutoComplete>();
  const [selectedDataRow, setSelectedDataRow] = useState<Subsidiary | Department | ExpenseType | CreditCard>();
  const [openFormDialog, setOpenFormDialog] = useState<boolean>(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState<boolean>(false);

  const { fetchData, setFetchData, isUserImageFetching } = useFetchContext();
  const { selectedTab, isFilterBtnSelected, setContextFilters } = useTabContext();
  const { enqueueSnackbar } = useSnackbar();

  const loadFilterAutoCompleteData = async () => {
    try {
      setIsFiltersLoading(true);
      let result: ExpenseTypeFilterAutoComplete = await getFilterAutoCompleteData();
      setFilterAutoCompleteData(result);
      setContextFilters && setContextFilters(result);
      setIsFiltersLoading(false);
      setIsLoading(false);
    } catch (error) {
      enqueueSnackbar(
        "Error retrieving auto complete data. Please try again. If the issue persists, contact Internal Apps Team",
        {
          variant: "error",
        }
      );
    } finally {
      setIsFiltersLoading(false);
    }
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        setIsLoading(true);
        setTableData([]);
        let result: Subsidiary[] | Department[] | ExpenseType[] = tablesWithFilters.includes(selectedTab)
          ? await getFilteredData(selectedTab, filters)
          : await getData(selectedTab);
        if (!isFiltersApplyBtnClicked && tablesWithFilters.includes(selectedTab)) {
          loadFilterAutoCompleteData();
        }
        setTableData(result);
      } catch (error) {
        enqueueSnackbar("Error retrieving data. Please try again. If the issue persists, contact Internal Apps Team", {
          variant: "error",
        });
      } finally {
        setIsLoading(false);
        setIsFiltersApplyBtnClicked(false);
        setFetchData(false);
      }
    };

    if (fetchData && !isUserImageFetching) {
      loadData();
    }
  }, [fetchData, isUserImageFetching]);

  useEffect(() => {
    if (!isUserImageFetching) {
      tablesWithFilters.includes(selectedTab) && loadFilterAutoCompleteData();
    }
  }, [isUserImageFetching]);

  const columns: GridColDef[] = tableColumns[selectedTab].map((column) => ({
    ...column,
    renderCell: (params) => {
      if (isLoading) {
        return (
          <TableCell sx={{ width: "100%", paddingLeft: 0 }}>
            <Skeleton animation="wave" variant="text" />
          </TableCell>
        );
      } else if (
        params.field === "engagementCodes" ||
        params.field === "engagementCodeSuffixes" ||
        params.field === "leadEmails"
      ) {
        if (params.value.length > 0) {
          return (
            <div>
              {params.value.map((element: string, index: number) => (
                <Chip
                  label={element}
                  key={index}
                  size="small"
                  title={element}
                  sx={{
                    marginBottom: "10px",
                    fontSize: 12,
                    fontWeight: 600,
                    marginLeft: "5px",
                    borderRadius: 2,
                    backgroundColor: params.field === "engagementCode" ? "#C2DEDC" : "#ECE3CE",
                    color: "black",
                  }}
                />
              ))}
            </div>
          );
        }
        return "-";
      } else if (params.field === "status") {
        return (
          <Chip
            label={params.value.toLowerCase()}
            title={params.value.toLowerCase()}
            size="small"
            sx={{
              borderRadius: 2,
              padding: "5px",
              fontWeight: 600,
              backgroundColor: params.value.toLowerCase() === "active" ? "#2A3F38" : "#814746",
              color: params.value.toLowerCase() === "active" ? "#239054" : "#EE7B7C",
            }}
          />
        );
      } else if (params.field === "glCode") {
        return <TooltipCell row={params.row} tooltipType={"glCodeTooltip"} />;
      } else if (params.field === "expenseType") {
        return <TooltipCell row={params.row} tooltipType={"expenseTypeTooltip"} />;
      } else {
        if (typeof params.value === "number") {
          return params.value !== 0 ? undefined : "-";
        } else {
          return params.value === null || params.value.length === 0 ? "-" : undefined;
        }
      }
    },
    valueFormatter: ({ value }) => {
      if (value && value.length > 0) {
        if (
          column.field === "engagementCodes" ||
          column.field === "engagementCodeSuffixes" ||
          column.field === "leadEmails"
        ) {
          return value.join(", ");
        }
        return value;
      }
      return "-";
    },
  }));

  columns.push({
    field: "actions",
    headerName: "Actions",
    headerAlign: "right",
    align: "right",
    sortable: false,
    flex: 1.2,
    disableColumnMenu: true,
    disableExport: true,
    filterable: false,
    renderCell: (params) => {
      if (isLoading) {
        return (
          <TableCell sx={{ width: "100%", paddingRight: 0 }}>
            <Skeleton animation="wave" variant="text" />
          </TableCell>
        );
      }
      return (
        <>
          <IconButton
            onClick={() => {
              setOpenFormDialog(true);
              setSelectedDataRow(params.row);
            }}
            sx={{ marginRight: 1 }}
            size="small"
          >
            <EditIcon sx={{ color: theme.palette.text.primary }} />
          </IconButton>
          <IconButton
            onClick={() => {
              setOpenDeleteConfirmation(true);
              setSelectedDataRow(params.row);
            }}
            size="small"
          >
            <DeleteIcon color="error" />
          </IconButton>
        </>
      );
    },
  });

  return (
    <>
      <FilterFields
        className={isFilterBtnSelected ? "visible" : "hidden"}
        filterAutoCompleteData={filterAutoCompleteData}
        setFilters={setFilters}
        filters={filters}
        setIsFiltersApplyBtnClicked={setIsFiltersApplyBtnClicked}
        isFiltersLoading={isFiltersLoading}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
      <ThemeProvider theme={tableTheme}>
        {isFiltersApplyBtnClicked === undefined && tablesWithFilters.includes(selectedTab) ? (
          <Box display="flex" alignItems="center" justifyContent="center" height="100%">
            {isFiltersLoading ? (
              <CircularProgress />
            ) : (
              <div style={{ textAlign: "center", width: "100%" }}>
                <Box sx={{ height: "100%", width: "100%" }}>
                  <img src={noFilterImg} height={"15%"} width={"15%"} alt="no filter" />
                  <Box height={"40px"} />
                  <Typography fontSize={18} fontWeight={500}>
                    Please select filters
                  </Typography>
                </Box>
              </div>
            )}
          </Box>
        ) : isLoading ? (
          <SkeletonTable />
        ) : (
          <DataGrid
            columns={columns}
            rows={tableData}
            initialState={{
              sorting: {
                sortModel: [{ field: initialSortColumn[selectedTab], sort: "asc" }],
              },
            }}
            sx={{
              marginTop: "20px",
              "& .MuiDataGrid-columnHeaderTitle": {
                whiteSpace: "normal",
                lineHeight: "normal",
              },
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": { py: "8px" },
            }}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                printOptions: { disableToolbarButton: true },
              },
            }}
            localeText={{
              toolbarFilters: "Column Search",
            }}
            disableColumnMenu
            disableColumnSelector
            disableDensitySelector
            disableRowSelectionOnClick
            getRowHeight={() => "auto"}
          />
        )}
      </ThemeProvider>
      {openFormDialog && (
        <FormDialog
          open={openFormDialog}
          handleFormDialogClose={() => setOpenFormDialog(false)}
          initialData={selectedDataRow!}
        />
      )}
      {openDeleteConfirmation && (
        <DeleteConfirmationDialog
          open={openDeleteConfirmation}
          handleDeleteConfirmationClose={() => setOpenDeleteConfirmation(false)}
          initialData={selectedDataRow!}
        />
      )}
    </>
  );
};
