import { createContext, useContext } from "react";
import { tabLabels } from "../constants";
import { ExpenseTypeFilterAutoComplete } from "../types";

export const TabContext = createContext<
  | {
      selectedTab: keyof typeof tabLabels;
      isFilterBtnSelected?: boolean;
      setIsFilterBtnSelected?: React.Dispatch<React.SetStateAction<boolean>>;
      contextFilters?: ExpenseTypeFilterAutoComplete | undefined;
      setContextFilters?: React.Dispatch<React.SetStateAction<ExpenseTypeFilterAutoComplete | undefined>>;
    }
  | undefined
>(undefined);

export const useTabContext = () => {
  const tabContext = useContext(TabContext);

  if (tabContext === undefined) {
    throw Error("Tab context must be initialized before use");
  }
  return tabContext;
};
