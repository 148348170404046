import { AppConfig, initialUserPrivilegeRequest } from "../config/config";
import { ApiService } from "./apiService";

export const getUserPrivileges = () => {
  return new Promise<number[]>((resolve, reject) => {
    if (initialUserPrivilegeRequest) {
      ApiService.getInstance()
        .get(AppConfig.serviceUrls.userPrivileges)
        .then(
          (res) => {
            if (res.status === 200) {
              var privileges: number[] = res.data.privileges;
              resolve(privileges);
            }
            reject("error");
          },
          (error) => {
            reject("error");
          }
        );
    } else {
      resolve([]);
    }
  });
};
