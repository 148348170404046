import axios, { AxiosInstance } from "axios";
import * as rax from "retry-axios";
import { endpoints, filterEndpoints, tabLabels } from "./constants";
import {
  CreditCardPatchPayload,
  CreditCardPayload,
  DepartmentPatchPayload,
  DepartmentPayload,
  ExpenseTypeFilter,
  ExpenseTypePatchPayload,
  ExpenseTypePayload,
  SubsidiaryPatchPayload,
  SubsidiaryPayload,
} from "./types";
import { ServiceBaseUrl } from "../config/config";

export class ApiService {
  private static _instance: AxiosInstance;
  private static _idToken: string;
  private static _token: string;

  constructor(idToken: string, callback: () => Promise<{ idToken: string }>) {
    ApiService._instance = axios.create({ baseURL: ServiceBaseUrl });
    rax.attach(ApiService._instance);
    ApiService._idToken = idToken;
    ApiService.updateRequestInterceptor();

    (ApiService._instance.defaults as unknown as rax.RaxConfig).raxConfig = {
      retry: 3,
      instance: ApiService._instance,
      httpMethodsToRetry: ["GET", "HEAD", "OPTIONS", "DELETE", "POST", "PATCH"],
      statusCodesToRetry: [[400, 401]],
      retryDelay: 100,

      onRetryAttempt: async (err) => {
        const cfg = rax.getConfig(err);

        var res = await callback();

        ApiService.updateTokens(res.idToken);

        ApiService._instance.interceptors.request.clear();
        ApiService.updateRequestInterceptor();
      },
    };
  }

  public static getInstance(): AxiosInstance {
    return ApiService._instance;
  }

  private static updateTokens(idToken: string) {
    ApiService._idToken = idToken;
  }

  private static updateRequestInterceptor() {
    ApiService._instance.interceptors.request.use(
      (config) => {
        config.headers.set("Authorization", "Bearer " + ApiService._idToken);
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );
  }
}

export const getUserInfo = async () => {
  return (await ApiService.getInstance().get("/user-info")).data;
};

export const getData = async (selectedTab: keyof typeof tabLabels) => {
  return (await ApiService.getInstance().get(endpoints[selectedTab])).data;
};

export const getFilteredData = async (selectedTab: keyof typeof tabLabels, filters: ExpenseTypeFilter) => {
  return (await ApiService.getInstance().post(filterEndpoints[selectedTab], filters)).data;
};

export const postData = async (
  selectedTab: keyof typeof tabLabels,
  payload: SubsidiaryPayload | DepartmentPayload | ExpenseTypePayload | CreditCardPayload
) => {
  await ApiService.getInstance().post(endpoints[selectedTab], payload);
};

export const deleteData = async (selectedTab: keyof typeof tabLabels, id: string) => {
  return (await ApiService.getInstance().delete(endpoints[selectedTab] + "/" + id)).status;
};

export const updateData = async (
  selectedTab: keyof typeof tabLabels,
  payload: DepartmentPatchPayload | SubsidiaryPatchPayload | ExpenseTypePatchPayload | CreditCardPatchPayload,
  id: string
) => {
  return await ApiService.getInstance().patch(endpoints[selectedTab] + "/" + id, payload);
};

export const getGlCodes = async () => {
  return (await ApiService.getInstance().get("/gl-codes")).data;
};

export const getEmployeesEmail = async () => {
  return (await ApiService.getInstance().get("/employees/email")).data;
};

export const getFilterAutoCompleteData = async () => {
  return (await ApiService.getInstance().get("/expense-types/autocomplete-values")).data;
};
